import React, { useState } from 'react';
import {useStripe, useElements } from '@stripe/react-stripe-js';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next';
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';
import { config } from '../constans';

export default function SofortCheckout() {

    const stripe = useStripe();
    const [loadingSofortBtn, setLoadingSofortBtn] = useState(false);
    const [user_name, setUserName] = useState("");
    const [email, setEmail] = useState("");

    let user_data = JSON.parse(localStorage.getItem('skipgireusertoken'));
    let tempUser = (localStorage.getItem('skiphire_guest_user')!==null) ? JSON.parse(localStorage.getItem('skiphire_guest_user')) : null;

    let order_price = localStorage.getItem('final_order_price');

    let user_discount = (user_data) ? user_data.discount : tempUser.discount;
    let api_token = (user_data) ? user_data.api_token : tempUser.api_token;
    
    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (event) => {

        setLoadingSofortBtn(true);
        event.preventDefault();

        let stripeParams = { 
            owner_id: localStorage.getItem('MAIN_OWNER_ID'),
            amount: parseInt(order_price), 
            payment_method_types:'sofort'
        };

        // payment intent
        await axios.post(config.API_BASE_URL+config.API_PAYMENT_INTENT, stripeParams, {
            headers: {
                'api-token': api_token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        .then(({ data }) => {
            
            stripe.confirmSofortPayment(data.data.client_secret, {
                payment_method: {
                    sofort: {
                        country: 'DE'
                    },
                    billing_details: {
                        name: user_name,
                        email: email,
                    },
                },
                // Return URL where the customer should be redirected after the authorization.
                return_url: window.location.protocol+'//'+window.location.host+"/thank-you",
            })
            .then(function(result) {
                if (result.error) {
                    setLoadingSofortBtn(false);
                    toast.error(result.error.message);
                }
            });
        })
        .catch(function (error) {
            if (error.response) {
                setLoadingSofortBtn(false);
            } else if (error.request) {
                setLoadingSofortBtn(false);
                // The request was made but no response was received
                alert(error.request);
            } else {
                setLoadingSofortBtn(false);
                // Something happened in setting up the request that triggered an Error
                toast.error(error.message);
            }
        
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}> 
                <div className="col-md-8 mt-3">
                    <label className="labels">{t('User Name')}</label>
                    <input 
                        type="text" 
                        className="form-control h-50" 
                        onChange={handleUserNameChange}
                        value={user_name} 
                        id="user_name" 
                        required
                    />
                </div>
                <div className="col-md-8 mt-3">
                    <label className="labels">{t('Email')}</label>
                    <input 
                        type="email" 
                        className="form-control h-50" 
                        onChange={handleEmailChange}
                        value={email} 
                        id="email" 
                        required
                    />
                </div>
                <button className="btn theme-btn mt-2 rounded-pill" disabled={!stripe || loadingSofortBtn}>{loadingSofortBtn && <LoadingSpinner />} { t('Submit') }</button>
            </form>
        </div>
    )
}
