import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetAllData } from '../Store/Slices/UserAuthSlice';

export default function Logout() {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(resetAllData);
      localStorage.removeItem('appoinmentForm');
      localStorage.removeItem('container_size');
      localStorage.removeItem('container_id');
      localStorage.removeItem('vat_tax');
      localStorage.removeItem('container_price');
      localStorage.removeItem('garbage_id');
      localStorage.removeItem('skipgireusertoken');
      localStorage.removeItem('payment_type');
      localStorage.removeItem('selected_address');
      localStorage.removeItem('payment_on_site_desc');
      localStorage.removeItem('container_name');
      localStorage.removeItem('garbage_desc');
      localStorage.removeItem('final_order_price');
      localStorage.removeItem('__paypal_storage__');
      window.location.href="/";
    }, [])
    
  return (
    <div>Logout</div>
  )
}
