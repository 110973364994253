import React, { useEffect,useState } from 'react'
import SidebarHotline from '../Components/SidebarHotline'
import FrontendLayout from '../Layout/FrontendLayout'
import LoadingSpinner from '../Components/LoadingSpinner'
import { getSavedAddress, storeAddressList, storeSaveAddressErrors, saveAddress, updateAddress } from './../Store/Slices/UserSavedAddressSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { t } from 'i18next'
import YellowHeader from '../Components/YellowHeader'

export default function SelectAddress() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let addressList = useSelector(storeAddressList);
    let saveAddressErrors = useSelector(storeSaveAddressErrors);
    let garbage_data = (localStorage.getItem('garbage_desc')!==null) ? JSON.parse(localStorage.getItem('garbage_desc')) : "";
    const selectedZipcode = (localStorage.getItem('selectedZIPCODE')!==null && localStorage.getItem('selectedZIPCODE')!=undefined) ? JSON.parse(localStorage.getItem('selectedZIPCODE')) : "";
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getSavedAddress()).then(() => {});
    }, [dispatch]);
    
    const is_material_order = localStorage.getItem('is_material_order');

    const [selectedAddress, setSelectedAddress] = useState({
        address_id:0,
        id: 0,
        delivery_street: '',
        delivery_address_2: '',
        delivery_city: '',
        delivery_zip_code: '',
        delivery_country: '',
        remarks: '',
    });

    const [loading, setLoading] = useState(false);
    const handleUpdateAddressClick = () => {
        setLoading(true);
        if (selectedAddress.id === 0) { //Add new address
            dispatch(saveAddress(selectedAddress)).then((data) => {
                if (data.status === 200) {
                    toast.success(data.message)
                    setSelectedAddress({
                        id: 0,
                        delivery_street: '',
                        delivery_address_2: '',
                        delivery_city: '',
                        delivery_zip_code: '',
                        delivery_country: '',
                        remarks: '',
                    });
                }
                setLoading(false);
            });
        } else { // Update address
            dispatch(updateAddress(selectedAddress)).then((data) => {
                if (data.status === 200) {
                    toast.success(data.message)
                    setSelectedAddress({
                        id: 0,
                        delivery_street: '',
                        delivery_address_2: '',
                        delivery_city: '',
                        delivery_zip_code: '',
                        delivery_country: '',
                        remarks: '',
                    });
                } else {
                    // toast.error(data.message)
                }
                setLoading(false);
            });
        }
    }

    const handleInputChange = (e) => {
        setSelectedAddress({ ...selectedAddress, [e.target.id]: e.target.value });
    }

    const handleSelectAddressClick = (address) => {
        if (selectedAddress.id === address.id) {
            setSelectedAddress({
                id: 0,
                delivery_street: '',
                delivery_address_2: '',
                delivery_city: '',
                delivery_zip_code: '',
                delivery_country: '',
                remarks: '',
            });
        } else {
            setSelectedAddress({ ...address, address_id:address.id });   
        }
    }
    
    const handleNextBtnClick = () => {
        if (selectedAddress.id === 0) {
            toast.error('Please select any address!');
            return;
        }

        if(selectedZipcode && parseInt(selectedZipcode[0].name)!=parseInt(selectedAddress.delivery_zip_code)){
            toast.error( t('Delivery address post code is not matched with previously entered post code') );
            return;
        }

        localStorage.setItem('selected_address', JSON.stringify(selectedAddress));
        if(is_material_order){
            navigate('/select-material-payment-method/');
        }else{
            navigate('/select-payment-method/' + id);
        }
    }

    const getFullAddress = (address) => {
        let add = address.delivery_street;
        add += (address.delivery_address_2) ? ', '+address.delivery_address_2 : '';
        add += (address.delivery_city) ? ', '+address.delivery_city : '';
        add += (address.delivery_zip_code) ? ', ' + address.delivery_zip_code : '';
        return add;
    }
    
    return (
        <FrontendLayout>
            <section className="container">
                <div className="row mt-5">
                    <YellowHeader title={ t('Where should the container be placed?') } />
                    <SidebarHotline />
                    <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 mt-5 order-md-2">
                        <div className="mb-3">
                            <ul className='nav'>
                            {addressList.map((address, index) => (
                                <li key={index} className={`card shadow w-100 rounded nav-item pt-3 ps-2 border-0 mt-2 ${selectedAddress.id === address.id ? 'theme-light-green' : 'bg-white'}`} style={{cursor:'pointer'}} onClick={()=>handleSelectAddressClick(address)}>
                                <p>{getFullAddress(address)}</p>
                                </li>
                            ))}
                            </ul>
                        </div>
                        <div className="row">
                            <div className="card border-0 shadow bg-white rounded p-3">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="text-right"><i className="fas fa-map-marker-alt"></i> {(selectedAddress.id!==0) ? t('Update address') : t('Add new address') }</h4>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="labels">{ t('Street') } *</label>
                                        <input type="text" className={`form-control h-50 ${saveAddressErrors && saveAddressErrors.hasOwnProperty('delivery_street') && 'is-invalid'}`} id='delivery_street' onChange={handleInputChange} value={selectedAddress.delivery_street} />
                                        {saveAddressErrors && saveAddressErrors.hasOwnProperty('delivery_street') && <div className="invalid-feedback">{saveAddressErrors.delivery_street}</div>}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="labels">{ t('Street 2') }</label>
                                        <input type="text" className={`form-control h-50 ${saveAddressErrors && saveAddressErrors.hasOwnProperty('delivery_address_2') && 'is-invalid'}`} id='delivery_address_2' onChange={handleInputChange} value={selectedAddress.delivery_address_2} />
                                        {saveAddressErrors && saveAddressErrors.hasOwnProperty('delivery_address_2') && <div className="invalid-feedback">{saveAddressErrors.delivery_address_2}</div>}
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label className="labels">{ t('Postal code') } *</label>
                                        <input type="text" className={`form-control h-50 ${saveAddressErrors && saveAddressErrors.hasOwnProperty('delivery_zip_code') && 'is-invalid'}`} id='delivery_zip_code' onChange={handleInputChange} value={selectedAddress.delivery_zip_code} />
                                        {saveAddressErrors && saveAddressErrors.hasOwnProperty('delivery_zip_code') && <div className="invalid-feedback">{saveAddressErrors.delivery_zip_code}</div>}
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <label className="labels">{ t('City') } *</label>
                                        <input type="text" className={`form-control h-50 ${saveAddressErrors && saveAddressErrors.hasOwnProperty('delivery_city') && 'is-invalid'}`} id='delivery_city' onChange={handleInputChange} value={selectedAddress.delivery_city} />
                                        {saveAddressErrors && saveAddressErrors.hasOwnProperty('delivery_city') && <div className="invalid-feedback">{saveAddressErrors.delivery_city}</div>}
                                    </div>
                                </div>
                                
                                <div className="mt-2 text-left "><button disabled={loading} onClick={handleUpdateAddressClick} className="btn theme-btn btn-sm profile-button " type="button">{loading && <LoadingSpinner />}{ t('Save Address') }</button></div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            {is_material_order ? <button className="col-md-2 btn theme-btn mt-2 rounded-pill" onClick={handleNextBtnClick} >{ t('Next') }</button> : <button className="col-md-2 btn theme-btn mt-2 rounded-pill" data-bs-toggle="modal" data-bs-target="#PickupDate"
                            >{ t('Next') }</button> }

                            {/* <a href="#" onClick={() => handlePickupRequestClick(orderdetails)} id={orderdetails.id} className="btn border border-dark border-1" data-bs-toggle="modal" data-bs-target="#PickupDate"><img height={25} className="me-2" src="assets/images/images/roundArrowLeft.png" />{ t('Pickup request') }</a> */}

                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" data-bs-backdrop="static" id="PickupDate" tabIndex="-1" role="dialog" aria-labelledby="PickupDateLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                            <h5 className="modal-title" id="PickupDateLabel">{ t('Das darf in den Container') }</h5>
                        {/* <i id='pickupRequestCloseBtn' className="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </i> */}
                    </div>
                    <div className="modal-body">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-sx-12 ">
                            <div className="card p-4" style={{backgroundColor:'#CFFAFF'}}>
                                <h2 style={{color:'#1AA3DB'}} className="float-left"><img alt="" src="/assets/images/images/valid.png" width="40"
                                        /> { t("Permitted") }</h2>
                                {garbage_data && <p>{ garbage_data.garbage_allowed_description }</p>}
                                <h2 style={{color:'#F44336'}} className="float-left"><img alt="" src="/assets/images/images/inValid.png"
                                        width="40" /> { t('Forbidden') }</h2>
                                <p>{garbage_data && garbage_data.garbage_notallowed_description}</p>
                            </div>
                        </div> 
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" onClick={handleNextBtnClick} className="btn border border-1 border-dark btn-dark" data-bs-dismiss="modal">{ t('Confirmation') }</button> 
                    </div>
                    </div>
                </div>
            </div>
        </FrontendLayout>
    )
}
