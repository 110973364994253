import React from 'react'
import FrontendLayout from '../../Layout/FrontendLayout'
import { t } from 'i18next';
import SidebarHotline from '../../Components/SidebarHotline';
import YellowHeader from '../../Components/YellowHeader';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { checkPostcode } from '../../Store/Slices/GarbageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getMaterialCategory, storeMaterialCategoryList } from '../../Store/Slices/MaterialCategorySlice';
import { useEffect } from 'react';
import MaterialCategoryCard from '../../Components/MaterialCategoryCard';

export default function MaterialCategory() {

    const dispatch = useDispatch();
    let AllCategory = useSelector(storeMaterialCategoryList);

    useEffect(() => {
        dispatch(getMaterialCategory({ owner_id: localStorage.getItem('MAIN_OWNER_ID') }));
    }, [dispatch])

    return (
    <FrontendLayout>
        <section className="container">
            <div className="row mt-5">
                <YellowHeader title={ t('Material Categories') } />
                <SidebarHotline />
                <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 col-md-12 mt-5 order-md-2">
                    <div className='row'>
                    { AllCategory.length > 0 && AllCategory.map(category => (
                        <div className="col-md-6" key={category.id}>
                            <MaterialCategoryCard category={category} />
                        </div>
                    ))}
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                    <button type="button" className="btn-close closeThisPopup" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </FrontendLayout>
    )
}
