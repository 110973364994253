import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import {config, CheckAuth} from './../../constans';

export const UserRegisterSlice = createSlice({
    name: 'UserRegisterSlice',
    initialState: {
          RegisterErrors: {},
          RegisterErrorsMessage: ''
    },
    reducers: {
      setRegisterErrors: (state, { payload }) => {
          state.RegisterErrors = payload.errors;
      },
      setRegisterErrorsMessage: (state, {payload}) => {
          state.RegisterErrorsMessage = payload.message;
      },
      resetAllData: (state) => {
          state.RegisterErrors = {};
          state.RegisterErrorsMessage = '';
      }
    },
})
  
// Action creators are generated for each case reducer function
export const { setRegisterErrors, setRegisterErrorsMessage, resetAllData } = UserRegisterSlice.actions
  
// Selectors
export const storeRegisterErrors = (state) => state.UserRegisterSlice.RegisterErrors;
export const storeRegisterErrorsMessage = (state) => state.UserRegisterSlice.RegisterErrorsMessage;
  
export default UserRegisterSlice.reducer
  
  // APi Function
export function Register(params = {}) {
    return async dispatch => {
        try {
            params = { ...params, owner_id: localStorage.getItem('MAIN_OWNER_ID'), device_type: 'web', device_token: 'WEB_TOKEN' };
            
            return await axios.post(config.API_BASE_URL+config.API_REGISTER, params)
                .then(({ data }) => {
                    if (data.status === 200) {
                        dispatch(resetAllData)   
                    } else {
                        dispatch(setRegisterErrors(data))
                        dispatch(setRegisterErrorsMessage(data))
                    }
                    return data;
                })
                .catch(function (error) {
                    CheckAuth(error.response.status);
                    dispatch(setRegisterErrors(error.response))
                });
        } catch (error) {
        }
    }
}