import React from 'react'
import FrontendLayout from '../../Layout/FrontendLayout'
import YellowHeader from '../../Components/YellowHeader'
import SidebarHotline from '../../Components/SidebarHotline'
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaterialProductCard from '../../Components/MaterialProductCard';
import MaterialContainerCard from '../../Components/MaterialContainerCard';
import { useState } from 'react';
import moment from 'moment';
import { storeUser } from '../../Store/Slices/UserAuthSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function MateriaContainer() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const owner_data = JSON.parse(localStorage.getItem('owner_data'));
    const materialCategory = JSON.parse(localStorage.getItem('selected_material_category'));
    const materialProduct = JSON.parse(localStorage.getItem('selected_material_product'));

    const [selectedContainer,setselectedContainer] = useState()

    let user = useSelector(storeUser);
    let tempUser = (localStorage.getItem('skiphire_guest_user')!==null) ? JSON.parse(localStorage.getItem('skiphire_guest_user')) : null;

    let placement_option_one = (owner_data) ? owner_data.placement_option_one : "";
    let placement_option_two = (owner_data) ? owner_data.placement_option_two : "";
    
    
    const addDaysInDate = (newDate, days) => {
        let count = 0;
        let finalDate;
        for (let i = 1; i <= days; i++) {
          const day = moment(newDate).add(i, 'day').format('dddd');
      
          if (day !== 'Sunday' && day !== 'Saturday') {
            count = count + 1;
          }
      
          if (count === 14) {
            finalDate = moment(newDate).add(i, 'day');
            break;
          }
        }
        return finalDate._d;
    };
    
    let newDate = addDaysInDate(new Date().setDate(new Date().getDate() + 1), 30);    
    let todays = moment(new Date()).format('dddd');


    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const [appoinmentForm, setAppoinmentForm] = useState({
        delivery_on: (todays === "Friday") ? new Date().setDate(new Date().getDate() + 3) : new Date().setDate(new Date().getDate() + 1),
        pickup_on: newDate,
        min_pickup_on: newDate,
        placement_option: "",
        pickup_time: 'whole_day',
        delivery_time: 'whole_day'
    })

    const handleAppoinmentFormDateInput = (date, field) => {
        if (field === 'delivery_on') {
            setAppoinmentForm({ ...appoinmentForm, delivery_on: date });
            // let newdelivery_on = new Date(date);
            let newmin_pickup_on = addDaysInDate(new Date(date), 30);
            setAppoinmentForm({ ...appoinmentForm, delivery_on: date, pickup_on: newmin_pickup_on, min_pickup_on: newmin_pickup_on});
        } else {
            setAppoinmentForm({ ...appoinmentForm, pickup_on: date });   
        }
    }

    const handleAppoinmentFormTimeInput = (event, field) => {
        // event.preventDefault();
        if (field === 'pickup_time') {
            setAppoinmentForm({ ...appoinmentForm, pickup_time: event.target.value });
        } else {
            setAppoinmentForm({ ...appoinmentForm, delivery_time: event.target.value });   
        }
    }

    const handlePlacementOption = (e) => {
        setAppoinmentForm({ ...appoinmentForm, placement_option: e.target.value });
    }

    const handleNextClick = () => {
        if (appoinmentForm.placement_option == "") {
            toast.error(t("Please select the placement option!"));
            return;
        }
        
        localStorage.setItem('appoinmentForm', JSON.stringify(appoinmentForm));
        localStorage.setItem('is_material_order', '1');
        
        localStorage.removeItem('material_loginFromPaymentSteps', 1);

        const isreorder = localStorage.getItem('is_reorder_material');
        
        if (isreorder === '1') {
            navigate('/select-payment-method/' + selectedContainer.id);
        } else {
            localStorage.setItem('material_container_id', selectedContainer.id);
            localStorage.setItem('material_container_price', selectedContainer.price_per_ton);
            localStorage.setItem('material_container_name', selectedContainer.title);
            localStorage.setItem('material_container', JSON.stringify(selectedContainer));
            navigate('/select-address/' + selectedContainer.id);
        }
    }

    const handleNonAuthAction = (e) => {
        if (appoinmentForm.placement_option == "") {
            toast.error(t("Please select the placement option!"));
            return;
        }

        localStorage.setItem('is_material_order', '1');
        localStorage.setItem('material_loginFromPaymentSteps', 1);
        localStorage.setItem('appoinmentForm', JSON.stringify(appoinmentForm));
        localStorage.setItem('material_container_id', selectedContainer.id);
        localStorage.setItem('material_container_price', selectedContainer.price_per_ton);
        localStorage.setItem('material_container_name', selectedContainer.title);
        localStorage.setItem('material_container', JSON.stringify(selectedContainer));
        navigate('/' + e.target.id);
    }

    useEffect(() => {
    }, [dispatch])

    return (
    <FrontendLayout>
        <section className="container">
            <div className="row mt-5">
                <YellowHeader title={ t('Material Product Containers') } />
                <SidebarHotline />
                <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 col-md-12 mt-5 order-md-2">
                    <h2>{materialCategory.title}</h2>
                    <div className='row'>
                        { materialProduct.containers.length > 0 && materialProduct.containers.map(container => (
                            <div className="col-md-6" key={container.id} onClick={(e)=>setselectedContainer(container)}>
                                <MaterialContainerCard productName={materialProduct.title} selectedContainer={selectedContainer} container={container} />
                                {/* <MaterialProductCard product={product} /> */}
                            </div>
                        ))}
                    </div>

                    <div className="row">
                        <strong>{ t('Important') }</strong>
                        <p>{materialCategory.main_description}</p>
                    </div>
                </div>
            </div>
            {selectedContainer && 
            <div className="row">
                {/* appointent */}
                <YellowHeader title={ t('Set an appointment') } />
                <div className='control-group col-md-6 mt-3'>
                    <label className='form-label'><strong>{ t('Delivery on') }</strong></label>
                    <DatePicker
                        className='form-control'
                        selected={appoinmentForm.delivery_on}
                        minDate={new Date().setDate(new Date().getDate() + 1)}
                        onChange={(date) => handleAppoinmentFormDateInput(date, 'delivery_on')}
                        filterDate={isWeekday}
                        dateFormat="dd.MM.yyyy"
                        name="delivery_on"
                    />
                    <label className='form-label mt-2'><strong>{ t('Delivery Time') }</strong></label>
                    <div className="form-check">
                        <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='whole_day' type="radio" name="delivery_on_time" id="delivery_on_time_whole_day" defaultChecked={appoinmentForm.delivery_time==='whole_day'} />
                        <label className="form-check-label" htmlFor="delivery_on_time_whole_day">{ t('Whole day (from 8:00 h till 16:00 h)') }</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='mornings' type="radio" defaultChecked={appoinmentForm.delivery_time==='mornings'} name="delivery_on_time" id="delivery_on_time_mornings"/>
                        <label className="form-check-label" htmlFor="delivery_on_time_mornings">{ t("Mornings (from 8:00 h till 12:30 h)") }</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='afternoon' type="radio" defaultChecked={appoinmentForm.delivery_time==='afternoon'} name="delivery_on_time" id="delivery_on_time_afternoon"/>
                        <label className="form-check-label" htmlFor="delivery_on_time_afternoon">{ t("Afternoon (from 12:30 h till 16:00 h)") }</label>
                    </div>
                </div>
                {/* Show pickup if container type is contaner and hide for raw and bigbag. */}
                {(selectedContainer && selectedContainer.container_type == 'container') &&
                <div className='control-group col-md-6 mt-3'>
                    <label className='form-label'><strong>{ t('Pickup on') }</strong></label>
                    <DatePicker
                        className='form-control'
                        selected={appoinmentForm.pickup_on}
                        minDate={appoinmentForm.min_pickup_on}
                        onChange={(date) => handleAppoinmentFormDateInput(date, 'pickup_on')}
                        filterDate={isWeekday}
                        dateFormat="dd.MM.yyyy"
                        name="pickup_on"
                    />
                    <label className='form-label mt-2'><strong>{ t('Pickup Time') }</strong></label>
                    <div className="form-check">
                        <input className="form-check-input" onClick={(e)=>handleAppoinmentFormTimeInput(e, 'pickup_time')} value='whole_day' type="radio" defaultChecked={appoinmentForm.pickup_time==='whole_day'} name="pickup_on_time" id="pickup_on_time_whole_day" />
                        <label className="form-check-label" htmlFor="pickup_on_time_whole_day">{ t('Whole day (from 8:00 h till 16:00 h)') }</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onClick={(e)=>handleAppoinmentFormTimeInput(e, 'pickup_time')} value='mornings' type="radio" defaultChecked={appoinmentForm.pickup_time==='mornings'} name="pickup_on_time" id="pickup_on_time_mornings"/>
                        <label className="form-check-label" htmlFor="pickup_on_time_mornings">{ t('Mornings (from 8:00 h till 12:30 h)') }</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onClick={(e)=>handleAppoinmentFormTimeInput(e, 'pickup_time')} value='afternoon' type="radio" defaultChecked={appoinmentForm.pickup_time==='afternoon'} name="pickup_on_time" id="pickup_on_time_afternoon"/>
                        <label className="form-check-label" htmlFor="pickup_on_time_afternoon">{ t('Afternoon (from 12:30 h till 16:00 h)') }</label>
                    </div>
                </div>
                }
                <div className='control-group col-12 mt-3'>
                    <label className='form-label'><strong>{ t('Placement options') }</strong></label>
                    <div className="form-check mt-4">
                        <input className="form-check-input" type="radio" onClick={handlePlacementOption} name="placement_option" id="placement_option1" value="placement_option_one" />
                        <label className="form-check-label" htmlFor="placement_option1">{placement_option_one}</label>
                    </div>
                    <div className="form-check mt-4">
                        <input className="form-check-input" type="radio" name="placement_option" onClick={handlePlacementOption} id="placement_option2" value="placement_option_two" />
                        
                        <label className="form-check-label" htmlFor="placement_option2">{placement_option_two}</label>
                    </div>
                </div>

                <div className="col-md-12 col-lg-12 col-sm-12 col-sx-12 mt-4 mx-auto justify-content-center">
                    { (() => {
                        if(Object.keys(user).length > 0 || (tempUser!==null && Object.keys(tempUser).length)) {
                            return (
                                <center>
                                    <button className="btn theme-btn mt-2 rounded-pill" onClick={handleNextClick}>{ t('Next') }</button>
                                </center>
                            )
                        } else {
                            return (
                                <center>
                                    <button className="btn theme-btn mt-2 rounded-pill" onClick={handleNonAuthAction} id="signup">{ t('For registration') }</button>
                                    <br />
                                    <button className="btn theme-btn mt-2 rounded-pill" onClick={handleNonAuthAction} id="signup-as-guest">{ t('Continue as a guest') }</button>
                                    <br />
                                    <button className="btn theme-btn mt-2 rounded-pill" onClick={handleNonAuthAction} id="login">{ t('Login with email') }</button>
                                </center>
                            )
                        }
                    }) ()}
                </div>
            </div>
            }
        </section>
    </FrontendLayout>
    )
}
