import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import {config, CheckAuth} from './../../constans';

export const GarbageSlice = createSlice({
    name: 'GarbageSlice',
    initialState: {
          garbageTypeList: [],
          containerList: [],
          zipCodeData: (localStorage.getItem('selectedZIPCODE')!=='' && (localStorage.getItem('selectedZIPCODE')!==undefined || localStorage.getItem('selectedZIPCODE')!=='undefined')) ? JSON.parse(localStorage.getItem('selectedZIPCODE')) : [],
          garbageErrors: '',
          garbageErrorMessage: ''
    },
    reducers: {
        setGarbageTypeList: (state, { payload }) => {
            state.garbageTypeList = payload.data;
        },
        setZipCodeData: (state, { payload }) => {
            if (payload.data !== undefined) {
                localStorage.setItem('selectedZIPCODE', JSON.stringify(payload.data));
                state.zipCodeData = payload.data;   
            }
        },
        resetZipCodeData: (state) => {
            localStorage.removeItem('selectedZIPCODE');
            state.zipCodeData = [];
        },
        setContainerList: (state, {payload}) => {
            state.containerList = payload;
        },
        setGarbageErrors: (state, {payload}) => {
            state.garbageErrors = payload.errors;
            state.garbageErrorMessage = payload.message;
        },
        resetGarbageErrors: (state) => {
            state.garbageErrors = {};
            state.garbageErrorMessage = '';
        }
    },
})
  
// Action creators are generated for each case reducer function
export const {
    setGarbageTypeList,
    setZipCodeData,
    resetZipCodeData,
    setGarbageErrors,
    resetGarbageErrors,
    setContainerList
} = GarbageSlice.actions
  
// Selectors
export const storeGarbageTypeList = state => state.GarbageSlice.garbageTypeList;
export const storeZipCodeData = state => state.GarbageSlice.zipCodeData;
export const storeContainerList = state => state.GarbageSlice.containerList;
export const storeGarbageErrors = state => state.GarbageSlice.garbageErrors;
export const storeGarbageErrorMessage = state => state.GarbageSlice.garbageErrorMessage;
  
export default GarbageSlice.reducer
  
  // API Function
export function getGarbageTypeList(owner_id) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_GARBAGE_TYPE_LIST, {owner_id:owner_id}, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setGarbageTypeList(data))
                } else {
                    dispatch(setGarbageErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setGarbageErrors(error.response))
            });
    }
}

export function checkPostcode(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_ZIPCODE_CHECK, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setZipCodeData(data));   
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(resetZipCodeData);
                dispatch(setGarbageErrors(error.response))
            });
    }
}

export function getContainerList(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_CONTAINER_LIST, params, options)
            .then(({ data }) => {
                dispatch(setContainerList(data));
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
            });
    }
}