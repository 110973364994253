import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBlogs, storeAllBlogs } from '../Store/Slices/BlogSlice'
import FrontendLayout from '../Layout/FrontendLayout'
import { useEffect } from 'react';
import BlogCard from '../Components/BlogCard';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import YellowHeader from '../Components/YellowHeader';

export default function BlogList() {
 
    const dispatch = useDispatch();
    let AllBlogs = useSelector(storeAllBlogs);

    useEffect(() => {
        dispatch(getAllBlogs({ owner_id: localStorage.getItem('MAIN_OWNER_ID') }));
    }, [dispatch])

    return (
        <FrontendLayout>
            <section className="container">
                <div className="row mt-5">
                    <YellowHeader title={ t('Blogs') } />
                    <div className="row mt-3">
                            { AllBlogs.length > 0 && AllBlogs.map(blog => (
                                <Link key={blog.id} className='col-md-3' to={`/blogs/${blog.id}`}>
                                    <BlogCard blog={blog} />
                                    </Link>
                            ))}
                    </div>
                </div>
            </section>
        </FrontendLayout>
    )
}
