import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { config, CheckAuth, getApiToken } from './../../constans';

export const ContactUsSlice = createSlice({
    name: 'ContactUsSlice',
    initialState: {
          contactUsResponse: {},
          contactUsErrors: [],
          contactUsErrorMessage: ''
    },
    reducers: {
        setContactUsResponse: (state, { payload }) => {
            state.contactUsResponse = payload.data;
            state.contactUsErrors = [];
            state.contactUsErrorMessage = '';
        },
        setcontactUsErrors: (state, { payload }) => {
            state.contactUsErrors = payload.errors;
            state.contactUsErrorMessage = payload.message;
        },
    },
})
  
// Action creators are generated for each case reducer function
export const {
    setContactUsResponse,
    setcontactUsErrors
} = ContactUsSlice.actions
  
// Selectors
export const storeContactUsResponse = state => state.ContactUsSlice.contactUsResponse;
export const storeContactUsErrors = state => state.ContactUsSlice.contactUsErrors;
export const storeContactUsErrorMessage = state => state.ContactUsSlice.contactUsErrorMessage;

  
export default ContactUsSlice.reducer
  
  // API Function
export function sendContactUs(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_CONTACT_OWNER_FOR_WEB, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setContactUsResponse(data))
                } else {
                    dispatch(setcontactUsErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setcontactUsErrors(error.response))
            });
    }
}