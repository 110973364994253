import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { config, CheckAuth } from './../../constans';

export const MaterialCategorySlice = createSlice({
    name: "MaterialCategorySlice",
    initialState: {
        materialCategoryList: [],
        materialCategoryErrors: '',
        materialCategoryErrorMessage: ''
    },
    reducers: {
        setMaterialCategoryList: (state, { payload }) => {
            state.materialCategoryList = payload.data
        },
        setMaterialCategoryErrors: (state, { payload }) => {
            state.materialCategoryErrors = payload.errors;
            state.materialCategoryErrorMessage = payload.message;
        },
        resetMaterialCategoryErrors: (state) => {
            state.materialCategoryErrors = {};
            state.materialCategoryErrorMessage = '';
        }
    },
})
// Action creators are generated for each case reducer function
export const { setMaterialCategoryList, setMaterialCategoryErrors, resetMaterialCategoryErrors } = MaterialCategorySlice.actions

// Selectors
export const storeMaterialCategoryList = state => state.MaterialCategorySlice.materialCategoryList;
export const storeMaterialCategoryErrors = state => state.MaterialCategorySlice.materialCategoryErrors;
export const storeMaterialCategoryErrorMessage = state => state.MaterialCategorySlice.materialCategoryErrorMessage;

export default MaterialCategorySlice.reducer

//getMaterialCategory

export function getMaterialCategory(owner_id) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
            }
        };
        return await axios.post(config.API_BASE_URL + config.API_MATERIAL_CATEGORY_LIST, { owner_id: owner_id }, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setMaterialCategoryList(data))
                } else {
                    dispatch(setMaterialCategoryErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setMaterialCategoryErrors(error.response))
            });
    }
}