import React, { useEffect, useState } from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import LoadingSpinner from './../Components/LoadingSpinner'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Login, storeAuthErrors, AuthErrorsMessage} from './../Store/Slices/UserAuthSlice'
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

export default function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    let errors = useSelector(storeAuthErrors);
    let errorMessage = useSelector(AuthErrorsMessage);
    const [loading, setLoading] = useState(false);
    const [loginForm, setloginForm] = useState({
        email: '',
        password: '',
        owner_id: localStorage.getItem('MAIN_OWNER_ID')
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    

    const handleLoginClick = () => {
        setLoading(true);
        dispatch(Login(loginForm)).then((data) => {
            if (data.status === 200) {
                setLoading(false);
                if (parseInt(localStorage.getItem('loginFromPaymentSteps')) === 1) {

                    const garbage_id = localStorage.getItem('garbage_id');
                    localStorage.removeItem('loginFromPaymentSteps');
                    if(localStorage.getItem('appoinmentForm')!=null && localStorage.getItem('appoinmentForm')!="" && localStorage.getItem('appoinmentForm')!="undefined"){
                        const isreorder = localStorage.getItem('is_reorder');
                        if (isreorder === '1') {
                            navigate('/select-payment-method/' + garbage_id);
                        } else {
                            navigate('/select-address/' + garbage_id);
                        }
                    }else{
                        navigate('/select-container/' + garbage_id);
                    }

                } else if(parseInt(localStorage.getItem('material_loginFromPaymentSteps')) === 1){
                    const material_container_id = localStorage.getItem('material_container_id');
                    localStorage.removeItem('material_loginFromPaymentSteps');
                    if(localStorage.getItem('appoinmentForm')!=null && localStorage.getItem('appoinmentForm')!="" && localStorage.getItem('appoinmentForm')!="undefined"){
                        navigate('/select-address/' + material_container_id);
                    }else{
                        navigate('/material-products-containers');
                    }
                } else {
                    navigate("/");   
                }
            } else {
                setLoading(false);   
            }
        });
    }

    const handleInputChange = (e) => {
        setloginForm({ ...loginForm, [e.target.id]: e.target.value });
    }

    return (
        <FrontendLayout>
            <div className='mx-auto'>
            <section className="mt-5" >
                <div className="container py-2 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-6">
                                {(errorMessage !== '') ? <div className='alert alert-danger'>{ errorMessage }</div> : '' }
                        <div className="card bg-light" style={{borderRadius: '1rem'}}>
                        <div className="row g-0">
                            <div className="col-md-12 col-lg-12 d-flex align-items-center">
                                <div className="card-body p-4 p-lg-5 text-black">
                                    <form>
                                        <h5 className="fw-normal mb-2 pb-3" style={{ letterSpacing: '1px' }}>{ t('Sign into your account') }</h5>
                                        <div className="form-outline mb-2">
                                            <label className="form-label" htmlFor="email">{ t('EMail') }</label>
                                            <input type="email" onChange={(e) => handleInputChange(e)} id="email" className={(errors && errors.hasOwnProperty('email')) ? 'form-control is-invalid' : 'form-control'} />
                                                            
                                            {(errors && errors.hasOwnProperty('email')) ? <div className="invalid-feedback">{errors.email}</div> : ''}
                                        </div>
                                        <div className="form-outline mb-2">
                                            <label className="form-label" htmlFor="password">{ t('Password') }</label>
                                            <input type="password" onChange={(e) => handleInputChange(e)} id="password" className={(errors &&errors.hasOwnProperty('password')) ? 'form-control is-invalid' : 'form-control'} />
                                            {(errors && errors.hasOwnProperty('password')) ? <div className="invalid-feedback">{errors.password}</div> : ''}
                                        </div>

                                        <div className="pt-1 mb-4">
                                            <button disabled={loading} className="btn btn-dark btn-block" type="button" onClick={handleLoginClick}>{loading && <LoadingSpinner/> }{ t('Login') }</button>
                                        </div>

                                        <p className="small text-muted" style={{color: '#393f81'}}>{ t("Forgot password?") } <Link to="/forgot-password" style={{ color: '#393f81' }}>{ t('Click here') }</Link></p>
                                        <p className="small text-muted" style={{color: '#393f81'}}>{ t("Don't have an account?") } <Link to="/signup" style={{color: '#393f81'}}>{ t("Register here") }</Link></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </FrontendLayout>
  )
}
