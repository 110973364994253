import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import {config, CheckAuth, getApiToken} from './../../constans';

export const UserSavedAddressSlice = createSlice({
    name: 'UserSavedAddressSlice',
    initialState: {
          AddressList: [],
          SaveAddressErrors: '',
          SaveAddressErrorMessage: ''
    },
    reducers: {
        setAddressList: (state, { payload }) => {
            state.AddressList = payload.data;
        },
        setSaveAddressErrors: (state, {payload}) => {
            state.SaveAddressErrors = payload.errors;
            state.SaveAddressErrorMessage = payload.message;
        },
        resetSaveAddressErrors: (state) => {
            state.SaveAddressErrors = {};
            state.SaveAddressErrorMessage = '';
        }
    },
})
  
// Action creators are generated for each case reducer function
export const { setAddressList, setSaveAddressErrors, resetSaveAddressErrors } = UserSavedAddressSlice.actions
  
// Selectors
export const storeAddressList = state => state.UserSavedAddressSlice.AddressList;
export const storeSaveAddressErrors = state => state.UserSavedAddressSlice.SaveAddressErrors;
export const storeSaveAddressErrorMessage = state => state.UserSavedAddressSlice.SaveAddressErrorMessage;
  
export default UserSavedAddressSlice.reducer
  
  // API Function
export function getSavedAddress() {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_ADDRESS_LIST, {}, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setAddressList(data))
                } else {
                    dispatch(setSaveAddressErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                // dispatch(setSaveAddressErrors(error.response))
            });
    }
}

export function saveAddress(params) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_ADD_ADDRESS, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(getSavedAddress());
                    dispatch(resetSaveAddressErrors());
                } else {
                    dispatch(setSaveAddressErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setSaveAddressErrors(error.response))
            });
    }
}

export function updateAddress(params) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_UPDATE_ADDRESS, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(getSavedAddress());
                    dispatch(resetSaveAddressErrors());
                } else {
                    dispatch(setSaveAddressErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setSaveAddressErrors(error.response))
            });
    }
}