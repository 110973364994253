import React from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import { config } from '../constans'

export default function AboutUs() {

  let owner_id = localStorage.getItem('MAIN_OWNER_ID');

  return (
    <FrontendLayout>
        <section className="container">
            <div className="row mt-3" style={{height:'700px'}}>
                    <iframe 
                      id="pagesIframe" 
                      title="aboutFrame" 
                      src={config.APP_URL+'page/'+owner_id+'/about-us'} 
                      frameBorder="0"
                    ></iframe>
            </div>
        </section>
    </FrontendLayout>
  )
}
