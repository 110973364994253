import React,{useEffect} from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import SidebarHotline from '../Components/SidebarHotline'
import TypeOfWasteCard from './../Components/TypeOfWasteCard'
import { getGarbageTypeList, storeGarbageTypeList } from './../Store/Slices/GarbageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import YellowHeader from '../Components/YellowHeader'
 
export default function SelectGarbageType() {
    let garbageTypes = useSelector(storeGarbageTypeList);

    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getGarbageTypeList(localStorage.getItem('MAIN_OWNER_ID')));
    }, [dispatch]);

    return (
        <FrontendLayout>
            <section className="container">
                <div className="row mt-5">
                    <YellowHeader title={ t('Select waste type') } />
                    <SidebarHotline />
                    <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 col-md-12 mt-5 order-md-2">
                        <div className="col-12">
                            <h2>{ t('Disposal of gypsum') }</h2>
                        </div>
                        <div className="row">
                        {garbageTypes.map(garbage_type => (
                            
                            <TypeOfWasteCard key={garbage_type.id} garbage={garbage_type} />
                            
                        ))}
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"></h5>
                                <button type="button" className="btn-close closeThisPopup" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            </div>
                        </div>
                    </div>
                    </div>
            </section>
        </FrontendLayout>
    )
}
