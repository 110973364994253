import { t } from 'i18next'
import React from 'react'

export default function ChildAccordionItem(props) {
  return (
      <div className="accordion-item border-0 shadow">
          
        <h2 className="accordion-header" id={`childAccordingHeadingOne-${props.type}-${props.order_document.id}`}>
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#childAccordingCollapseOne-${props.type}-${props.order_document.id}`} aria-expanded="false" aria-controls={`childAccordingCollapseOne-${props.type}-${props.order_document.id}`}>
                  <img src="assets/images/images/folderGreen.png" className='me-2' alt="..." />
                    { (() => {
                        if(props.type === 'delivery') {
                            return (
                                <strong>{ t('Delivered notes') }</strong>
                            )
                        } else if(props.type === 'picked_up_notes'){
                            return (
                                <strong>{ t('Picked up Notes') }</strong>
                            )
                        } else {
                            return (
                                <strong>{ t('Invoice') }</strong>
                            )
                        }
                    }) ()}
                    {/* {props.order_document.order_id} */}
            </button>
        </h2>
        <div id={`childAccordingCollapseOne-${props.type}-${props.order_document.id}`} className="accordion-collapse collapse" aria-labelledby={`childAccordingHeadingOne-${props.type}-${props.order_document.id}`} data-bs-parent={`childAccording-${props.type}-${props.order_document.id}`}>
            <div className="accordion-body">
            { (() => {
                if(props.type === 'delivery') {
                    return (
                        <strong><a href={props.order_document.delivered_notes ? props.order_document.delivered_notes : '#'} target="_blakn">{ t('View Delivery Notes') }</a></strong>
                    )
                } else if(props.type === 'picked_up_notes'){
                    return (
                        <strong><a href={props.order_document.picked_up_notes ? props.order_document.picked_up_notes : '#'} target="_blakn">{ t('View  Picked up Notes') }</a></strong>
                    )
                } else {
                    return (
                        <strong><a href={props.order_document.invoice ? props.order_document.invoice : '#'} target="_blakn">{ t('View Invoice') }</a></strong>
                    )
                }
            }) ()}
            </div>
        </div>
    </div>
  )
}
