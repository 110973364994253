import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import {config, CheckAuth, getApiToken} from './../../constans';

export const UserAuthSlice = createSlice({
  name: 'UserAuthSlice',
  initialState: {
        User: (localStorage.getItem('skipgireusertoken')) ? JSON.parse(localStorage.getItem('skipgireusertoken')) : {},
        AuthErrors: {},
        AuthErrorsMessage: ''
  },
  reducers: {
    setAuthUser: (state, {payload}) => {
          state.User = payload.data;
          state.AuthErrors = {};
          state.AuthErrorsMessage = '';
          localStorage.setItem('skipgireusertoken', JSON.stringify(payload.data));
    },
    setAuthErrors: (state, { payload }) => {
        state.AuthErrors = payload.errors;
    },
    setAuthErrorsMessage: (state, {payload}) => {
        state.AuthErrorsMessage = payload.message;
    },
    resetAllData: (state) => {
        state.User = {};
        state.AuthErrors = {};
        state.AuthErrorsMessage = '';
        localStorage.removeItem('skipgireusertoken')
    },
    resetAllErrors: (state) => {
        state.AuthErrors = {};
        state.AuthErrorsMessage = '';
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAuthUser, setAuthErrors, setAuthErrorsMessage, resetAllData, resetAllErrors } = UserAuthSlice.actions

// Selectors
export const storeUser = (state) => state.UserAuthSlice.User
export const storeAuthErrors = (state) => state.UserAuthSlice.AuthErrors;
export const AuthErrorsMessage = (state) => state.UserAuthSlice.AuthErrorsMessage;

export default UserAuthSlice.reducer

// APi Function
export function Login(params = {}) {
    return async dispatch => {
        try {
            params = { ...params, device_type: 'web', device_token: 'WEB_TOKEN' };
            
            return await axios.post(config.API_BASE_URL+config.API_LOGIN, params)
                .then(({ data }) => {
                    if (data.status === 200) {
                        dispatch(setAuthUser(data))   
                    } else {
                        dispatch(setAuthErrors(data))
                        dispatch(setAuthErrorsMessage(data))
                    }
                    return data;
                })
                .catch(function (error) {
                    CheckAuth(error.response.status);
                    dispatch(setAuthErrors(error.response))
                });
        } catch (error) {
        }
    }
}

export function Logout() {
    
    return async dispatch => {
        try {
            const options = {
                headers: {
                    'Accept': 'application/json',
                    'api-token': getApiToken(),
                }
            };
            return await axios.post(config.API_BASE_URL+config.API_LOGOUT, {}, options)
                .then(({ data }) => {
                    if (data.status === 200) {
                        dispatch(resetAllData)   
                    } else {
                        if (data.status === 401) {
                            dispatch(resetAllData)
                        }
                        dispatch(setAuthErrorsMessage(data))
                    }
                    return data;
                })
                .catch(function (error) {
                    CheckAuth(error.response.status);
                    dispatch(setAuthErrorsMessage(error))
                });
        } catch (error) {
        }
    }
}

export function UpdateProfile(params = {}) {
    return async dispatch => {
        try {
            const options = {
                headers: {
                    // 'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'api-token': getApiToken(),
                }
            };
            return await axios.post(config.API_BASE_URL+config.API_UPDATE_PROFILE, params, options)
                .then(({ data }) => {
                    if (data.status === 200) {
                        dispatch(setAuthUser(data))   
                    } else {
                        if (data.status === 401) {
                            dispatch(resetAllData)
                        }
                        dispatch(setAuthErrors(data))
                        dispatch(setAuthErrorsMessage(data))
                    }
                    return data;
                })
                .catch(function (error) {
                    CheckAuth(error.response.status);
                    dispatch(setAuthErrorsMessage(error))
                });
        } catch (error) {
        }
    }
}

export function changePassword(params = {}) {
    return async dispatch => {
        try {
            const options = {
                headers: {
                    // 'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'api-token': getApiToken(),
                }
            };
            return await axios.post(config.API_BASE_URL+config.API_CHANGE_PASSWORD, params, options)
                .then(({ data }) => {
                    if (data.status === 200) {
                        dispatch(resetAllErrors)
                        return data;
                    } else {
                        if (data.status === 401) {
                            dispatch(resetAllData)
                        }
                        dispatch(setAuthErrors(data))
                        dispatch(setAuthErrorsMessage(data))
                    }
                    return data;
                })
                .catch(function (error) {
                    CheckAuth(error.response.status);
                    dispatch(setAuthErrorsMessage(error))
                });
        } catch (error) {
        }
    }
}

export function forgotPassword(params = {}) {
    return async dispatch => {
        try {
            const options = {
                headers: {
                    'Accept': 'application/json',
                }
            };
            return await axios.post(config.API_BASE_URL+config.API_FORGOT_PASSWORD, params, options)
                .then(({ data }) => {
                    if (data.status === 200) {
                        dispatch(resetAllErrors)
                    } else {
                        if (data.status === 401) {
                            dispatch(resetAllData)
                        }
                    }
                    return data;
                })
                .catch(function (error) {
                    CheckAuth(error.response.status);
                    dispatch(setAuthErrorsMessage(error))
                });
        } catch (error) {
        }
    }
}