import React from 'react'
import FooterPaymentSupports from '../Components/FooterPaymentSupports';

export default function FooterSupportImage() {

  const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';

  return (
    <section className="container">
        <div className="row mt-5 mb-5 justify-content-center">
            {owner_data && owner_data.payment_status.map((val, index) => <FooterPaymentSupports key={index} payment_data={val} />) }
        </div>
    </section>
  )
}
