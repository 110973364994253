import React from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import BlogCard from '../Components/BlogCard';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import YellowHeader from '../Components/YellowHeader';

export default function AllBlogs() {

    const styles = {
        backgroundImageStyleForMaterial : {
            backgroundImage: 'url(/assets/images/images/material_category-modified.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '10px',
            minHeight: '250px',
        },
        backgroundImageStyleForBlog : {
            backgroundImage: 'url(/assets/images/images/blog.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '10px',
            minHeight: '250px',
        },
        LabelStyle:{
            textDecoration: 'navajowhite',
            color: '#000',
            background: '#ffffffbd',
            padding: '8px',
            width: '150px',
            textAlign: 'center',
            borderRadius: '10px',
            fontWeight: 600,
        }
    }

    return (
        <FrontendLayout>
            <section className="container">
                <div className="row mt-5">
                    <YellowHeader title={ t('Blogs') } />
                    <div className="col-md-6 mt-3">
                        <div className="card" style={styles.backgroundImageStyleForMaterial}>
                            <div className="card-body" style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                            <Link to={'/material-zipcode'} style={styles.LabelStyle}>{t('Materials')}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="card" style={styles.backgroundImageStyleForBlog}>
                            <div className="card-body" style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                            <Link to={'/blogs-list'} style={styles.LabelStyle}>{t('Offers')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </FrontendLayout>
    )
}
