import React, { useEffect, useState } from 'react'
import SidebarHotline from '../Components/SidebarHotline'
import FrontendLayout from '../Layout/FrontendLayout'
import ContainerTypeCard from '../Components/ContainerTypeCard'
import { storeZipCodeData, getContainerList, storeContainerList } from './../Store/Slices/GarbageSlice'
import { storeUser } from './../Store/Slices/UserAuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { t } from 'i18next'
import YellowHeader from '../Components/YellowHeader'
import moment from 'moment'
import { ownerDomains, sevenDaysDomains } from '../constans';

export default function SelectContainer(props) {
    
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let is_reorder = localStorage.getItem('is_reorder');
    let new_reorder_container_id = localStorage.getItem('new_reorder_container_id');
    const [showAppoinmentForm, setShowAppoinmentForm] = useState(false); 
    const [selectedContainer, setSelectedContainer] = useState(0); 
    const [selectedContainerPrice, setSelectedContainerPrice] = useState(0); 
    const [selectedContainerName, setSelectedContainerName] = useState(""); 
    const [selectedContainerSize, setselectedContainerSize] = useState(""); 
    const [vatTax, setVatTax] = useState(0);

    const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';

    const isDoaminFound = ownerDomains().some(domain => domain.id === owner_data.id);
    const isSevenDaysDoaminFound = sevenDaysDomains().some(domain => domain.id === owner_data.id);

    let placement_option_one = (owner_data) ? owner_data.placement_option_one : "";
    let placement_option_two = (owner_data) ? owner_data.placement_option_two : "";

    const addDaysInDate = (newDate, days) => {
        let count = 0;
        let finalDate;
        for (let i = 1; i <= days; i++) {
            const day = moment(newDate).add(i, 'day').format('dddd');
      
            if (day !== 'Sunday' && day !== 'Saturday') {
                count = count + 1;
            }
          
        //   if (isDoaminFound) {
        //     var pickupDay = 15;
        //   } else {
        //     var pickupDay = 10;
        //   }

            var pickupDay = isDoaminFound ? 15 : (isSevenDaysDoaminFound ? 7 : 10);
        
            if (count === pickupDay) {
                finalDate = moment(newDate).add(i, 'day');
                break;
            }
        }
        return finalDate._d;
    };
    let todays = moment(new Date()).format('dddd');
    
    const getDeliveryDate = (daysToAdd, todays) => {
        const today = new Date();
        let deliveryDate = new Date(today.setDate(today.getDate() + daysToAdd));
        if (todays === "Friday") {
            deliveryDate.setDate(today.getDate() + 3);  // Shift delivery to Monday
        }
        return deliveryDate;
    }

    const getNewDate = (daysToAdd) => {
        const today = new Date();
        return addDaysInDate(today.setDate(today.getDate() + daysToAdd), 40);
    }

    let newDate, delivery_date_on;
    let minDate = new Date().setDate(new Date().getDate() + 1);
    if (isDoaminFound) {
        newDate = getNewDate(2);
        delivery_date_on = getDeliveryDate(2, todays);
    } else if(isSevenDaysDoaminFound) {
        newDate = getNewDate(9); 
        delivery_date_on = getDeliveryDate(9, todays);
        minDate = new Date().setDate(new Date().getDate() + 9)
    } else {
        newDate = getNewDate(1);
        delivery_date_on = getDeliveryDate(1, todays);
    }

    const [appoinmentForm, setAppoinmentForm] = useState({
        delivery_on: delivery_date_on,
        pickup_on: newDate,
        min_pickup_on: (todays === "Friday") ? new Date().setDate(new Date().getDate() + 4) : new Date().setDate(new Date().getDate() + 3),
        placement_option: "",
        pickup_time: 'whole_day',
        delivery_time: 'whole_day'
    });

    let containerList = useSelector(storeContainerList);
    let zipCodeData = useSelector(storeZipCodeData);
    let user = useSelector(storeUser);
    let tempUser = (localStorage.getItem('skiphire_guest_user')!==null) ? JSON.parse(localStorage.getItem('skiphire_guest_user')) : null;

    const params = { 
        owner_id: localStorage.getItem('MAIN_OWNER_ID'), 
        user_id: (Object.keys(user).length > 0) ? user.id : '0', 
        postcode: zipCodeData[0].name, 
        garbage_id: localStorage.getItem('garbage_id'), 
    };

    const handleSelectContainerClick = (containerId, containerPrice, containerName, containerSize, vatTax) => {
        
        if (showAppoinmentForm && containerId === selectedContainer) {
            setSelectedContainer(0);
            setSelectedContainerPrice(0);
            setSelectedContainerName("");
            setselectedContainerSize("");
            setVatTax(0);
            setShowAppoinmentForm(false);
        } else {
            setShowAppoinmentForm(true);
            setSelectedContainer(containerId);
            setSelectedContainerPrice(containerPrice);
            setSelectedContainerName(containerName);
            setselectedContainerSize(containerSize)
            setVatTax(vatTax);
            setTimeout(() => {
                const element = document.getElementById('appoinmentForm');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300);
        }
    }

    const handleAppoinmentFormDateInput = (date, field) => {
        if (field === 'delivery_on') {

            setAppoinmentForm({ ...appoinmentForm, delivery_on: date });
            let newmin_pickup_on = addDaysInDate(new Date(date), 40);

            let new_pick_in_date = (todays === "Friday") ? moment(date).add(3, 'day') : moment(date).add(1, 'day');
            setAppoinmentForm({ ...appoinmentForm, delivery_on: date, pickup_on: newmin_pickup_on, min_pickup_on: new_pick_in_date._d});

        } else {
            setAppoinmentForm({ ...appoinmentForm, pickup_on: date });   
        }
    }

    const handleAppoinmentFormTimeInput = (event, field) => {
        // event.preventDefault();
        if (field === 'pickup_time') {
            setAppoinmentForm({ ...appoinmentForm, pickup_time: event.target.value });
        } else {
            setAppoinmentForm({ ...appoinmentForm, delivery_time: event.target.value });   
        }
    }

    const handlePlacementOption = (e) => {
        setAppoinmentForm({ ...appoinmentForm, placement_option: e.target.value });
    }

    const handleNextClick = () => {
        if (appoinmentForm.placement_option == "") {
            toast.error(t("Please select the placement option!"));
            return;
        }
        
        localStorage.setItem('appoinmentForm', JSON.stringify(appoinmentForm));
        
        const isreorder = localStorage.getItem('is_reorder');
        
        localStorage.removeItem('loginFromPaymentSteps');

        if (isreorder === '1') {
            navigate('/select-payment-method/' + id);
        } else {
            localStorage.setItem('container_id', selectedContainer);
            localStorage.setItem('container_price', selectedContainerPrice);
            localStorage.setItem('container_name', selectedContainerName);
            localStorage.setItem('container_size', selectedContainerSize);
            localStorage.setItem('vat_tax', vatTax);
            navigate('/select-address/' + id);
        }
    }

    const handleNonAuthAction = (e) => {
        if (appoinmentForm.placement_option == "") {
            toast.error(t("Please select the placement option!"));
            return;
        }
        localStorage.setItem('loginFromPaymentSteps', 1);
        localStorage.setItem('appoinmentForm', JSON.stringify(appoinmentForm));
        localStorage.setItem('container_id', selectedContainer);
        localStorage.setItem('container_price', selectedContainerPrice);
        localStorage.setItem('container_name', selectedContainerName);
        localStorage.setItem('container_size', selectedContainerSize);
        localStorage.setItem('vat_tax', vatTax);
        navigate('/' + e.target.id);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getContainerList(params)).then((data) => {
            
            if(data.garbage_data) {
                localStorage.setItem('garbage_desc', JSON.stringify({garbage_allowed_description: data.garbage_data.garbage_allowed_description, garbage_notallowed_description: data.garbage_data.garbage_notallowed_description}));    
            }
            
            if (new_reorder_container_id && new_reorder_container_id > 0) {
                
                const tempCont = data.data.filter((cont) => cont.id === new_reorder_container_id);
                setSelectedContainer(new_reorder_container_id);
                setSelectedContainerPrice(tempCont[0].price)
                setShowAppoinmentForm(true);
            }
        });
        if (is_reorder === '1') {
            setShowAppoinmentForm(true);
        }
    }, [dispatch, is_reorder, new_reorder_container_id]);

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    return (
        <FrontendLayout>
            <section className="container">
                <div className="row mt-5">
                    <YellowHeader title={ t('Determine container size') } />
                    <SidebarHotline />
                    <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 mt-5 order-md-2">
                        <div className="col-12">
                        { containerList.length !== 0 && 
                            <h2>{ containerList.garbage_data.garbage_name }</h2>
                        }
                        </div>
                        <div className="row">
                            {((is_reorder===undefined || is_reorder===null || is_reorder===0) && containerList.length !== 0) && containerList.data.map(container => (
                                <div className="col-md-6 col-lg-6 col-sm-6 col-sx-6" key={container.id} onClick={()=>handleSelectContainerClick(container.id, container.price, container.name, container.size, container.vat_tax)}>
                                    <ContainerTypeCard selectedContainer={selectedContainer} container={container} />
                                </div>
                            ))}
                        </div>
                        
                        {/* form */}
                        {showAppoinmentForm && <div className="row mt-5" id="appoinmentForm">
                            <div className='col-12 theme-light-yellow p-4 rounded theme-font-blue'>
                                <h2>{ t('Set an appointment') }</h2>
                            </div>
                            <div className='control-group col-md-6 mt-3'>
                                <label className='form-label'><strong>{ t('Delivery on') }</strong></label>
                                <DatePicker
                                    className='form-control'
                                    selected={appoinmentForm.delivery_on}
                                    minDate={new Date().setDate(new Date().getDate() + 1)}
                                    onChange={(date) => handleAppoinmentFormDateInput(date, 'delivery_on')}
                                    filterDate={isWeekday}
                                    dateFormat="dd.MM.yyyy"
                                    name="delivery_on"
                                />
                                <label className='form-label mt-2'><strong>{ t('Delivery Time') }</strong></label>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='whole_day' type="radio" name="delivery_on_time" id="delivery_on_time_whole_day" defaultChecked={appoinmentForm.delivery_time==='whole_day'} />
                                    <label className="form-check-label" htmlFor="delivery_on_time_whole_day">{ t('Whole day (from 8:00 h till 16:00 h)') }</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='mornings' type="radio" defaultChecked={appoinmentForm.delivery_time==='mornings'} name="delivery_on_time" id="delivery_on_time_mornings"/>
                                    <label className="form-check-label" htmlFor="delivery_on_time_mornings">{ t("Mornings (from 8:00 h till 12:30 h)") }</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='afternoon' type="radio" defaultChecked={appoinmentForm.delivery_time==='afternoon'} name="delivery_on_time" id="delivery_on_time_afternoon"/>
                                    <label className="form-check-label" htmlFor="delivery_on_time_afternoon">{ t("Afternoon (from 12:30 h till 16:00 h)") }</label>
                                </div>
                            </div>
                            <div className='control-group col-md-6 mt-3'>
                                <label className='form-label'><strong>{ t('Pickup on') }</strong></label>
                                <DatePicker
                                    className='form-control'
                                    selected={appoinmentForm.pickup_on}
                                    minDate={appoinmentForm.min_pickup_on}
                                    onChange={(date) => handleAppoinmentFormDateInput(date, 'pickup_on')}
                                    filterDate={isWeekday}
                                    dateFormat="dd.MM.yyyy"
                                    name="pickup_on"
                                />
                                <label className='form-label mt-2'><strong>{ t('Pickup Time') }</strong></label>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={(e)=>handleAppoinmentFormTimeInput(e, 'pickup_time')} value='whole_day' type="radio" defaultChecked={appoinmentForm.pickup_time==='whole_day'} name="pickup_on_time" id="pickup_on_time_whole_day" />
                                    <label className="form-check-label" htmlFor="pickup_on_time_whole_day">{ t('Whole day (from 8:00 h till 16:00 h)') }</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={(e)=>handleAppoinmentFormTimeInput(e, 'pickup_time')} value='mornings' type="radio" defaultChecked={appoinmentForm.pickup_time==='mornings'} name="pickup_on_time" id="pickup_on_time_mornings"/>
                                    <label className="form-check-label" htmlFor="pickup_on_time_mornings">{ t('Mornings (from 8:00 h till 12:30 h)') }</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={(e)=>handleAppoinmentFormTimeInput(e, 'pickup_time')} value='afternoon' type="radio" defaultChecked={appoinmentForm.pickup_time==='afternoon'} name="pickup_on_time" id="pickup_on_time_afternoon"/>
                                    <label className="form-check-label" htmlFor="pickup_on_time_afternoon">{ t('Afternoon (from 12:30 h till 16:00 h)') }</label>
                                </div>
                            </div>
                            <div className='control-group col-12 mt-3'>
                                <label className='form-label'><strong>{ t('Placement options') }</strong></label>
                                <div className="form-check mt-4">
                                    <input className="form-check-input" type="radio" onClick={handlePlacementOption} name="placement_option" id="placement_option1" value="placement_option_one" />
                                    <label className="form-check-label" htmlFor="placement_option1">{placement_option_one}</label>
                                </div>
                                <div className="form-check mt-4">
                                    <input className="form-check-input" type="radio" name="placement_option" onClick={handlePlacementOption} id="placement_option2" value="placement_option_two" />
                                    
                                    <label className="form-check-label" htmlFor="placement_option2">{placement_option_two}</label>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-sx-12 mt-4 mx-auto justify-content-center">
                            { (() => {
                                if(Object.keys(user).length > 0 || (tempUser!==null && Object.keys(tempUser).length)) {
                                    return (
                                        <center>
                                            <button className="btn theme-btn mt-2 rounded-pill" onClick={handleNextClick}>{ t('Next') }</button>
                                        </center>
                                    )
                                } else {
                                    return (
                                        <center>
                                            <button className="btn theme-btn mt-2 rounded-pill" onClick={handleNonAuthAction} id="signup">{ t('For registration') }</button>
                                            <br />
                                            <button className="btn theme-btn mt-2 rounded-pill" onClick={handleNonAuthAction} id="signup-as-guest">{ t('Continue as a guest') }</button>
                                            <br />
                                            <button className="btn theme-btn mt-2 rounded-pill" onClick={handleNonAuthAction} id="login">{ t('Login with email') }</button>
                                        </center>
                                    )
                                }
                            }) ()}
                            </div>
                        </div>}
                        {/* Form End */}
                        <hr />
                        <div className="row mt-5">
                            <div className="col-md-6 col-lg-6 col-sm-6 col-sx-12 ">
                                <h3>{ t("This waste belongs in the container for Rigips and Ytong") }</h3>
                                { containerList.length !== 0 && 
                                    <p className="mt-4">{ containerList.garbage_data.garbage_allowed_description }</p>
                                }
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6 col-sx-12">
                                <h3>{ t("This waste does not belong in the container for plasterboard and ytong stones") }</h3>
                                { containerList.length !== 0 && 
                                    <p className="mt-4">{ containerList.garbage_data.garbage_notallowed_description }</p>
                                }
                            </div>

                        </div>
                        <div className="row mt-5">
                            <div className="col-md-6 col-lg-6 col-sm-6 col-sx-12 ">
                                <div className="card p-4" style={{backgroundColor:'#CFFAFF'}}>
                                    <h2 style={{color:'#1AA3DB'}} className="float-left"><img alt="" src="/assets/images/images/valid.png" width="40"
                                         /> { t("Permitted") }</h2>
                                    {containerList.length !== 0 && <p>{ containerList.garbage_data.garbage_do_it }</p>}
                                    <h2 style={{color:'#F44336'}} className="float-left"><img alt="" src="/assets/images/images/inValid.png"
                                            width="40" /> { t('Forbidden') }</h2>
                                    <p>{containerList.length !== 0 && containerList.garbage_data.garbage_do_not}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6 col-sx-12">
                                <div className="card p-4 rounded text-center justify-content-center"
                                    style={{backgroundColor:'#D1EFD0'}}>
                                    <img className="mx-auto mt-3" src="/assets/images/images/valid.png" width="40" alt="" />
                                    <h3 className="mt-3">{ t('Fill containers flat') }</h3>
                                    <img className="mx-auto mt-3" src="/assets/images/images/containerEmpty.png" width="140" alt="" />
                                    <img className="mx-auto mt-3" src="/assets/images/images/inValid.png" width="40" alt="" />
                                    <h3 className="mt-3">{ t("Don't pile up the content") }</h3>
                                    <img className="mx-auto mt-3" src="/assets/images/images/containerFull.png" width="140" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </FrontendLayout>
    )
}
