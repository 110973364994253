import React from 'react'

export default function FooterPaymentSupports({payment_data}) {
  return (
    <>
        {(() => {
            if(payment_data.status=='active'){
                if (payment_data.payment_type=='credit_card'){
                    return (
                        <>
                        <div className='col-md-2'><img src={'/assets/images/'+payment_data.payment_type+'.png'} alt={payment_data.payment_type} width={100} /></div>
                        <div className='col-md-2'><img src={'/assets/images/credit_card2.png'} alt={payment_data.payment_type} width={100} /></div>
                        </>
                    )
                }
                
                return <div className='col-md-2'><img src={'/assets/images/'+payment_data.payment_type+'.png'} alt={payment_data.payment_type} width={100} /></div>;
            }
        })()}
    </>
  )
}
