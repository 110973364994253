import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { config, CheckAuth, getApiToken } from './../../constans';
import { param } from "jquery";

export const MaterialProductSlice = createSlice({
    name: "MaterialProductSlice",
    initialState: {
        materialProductList: [],
        materialProductErrors: '',
        materialProductErrorMessage: ''
    },
    reducers: {
        setMaterialProductList: (state, { payload }) => {
            state.materialProductList = payload.data
        },
        setMaterialProductErrors: (state, { payload }) => {
            state.materialProductErrors = payload.errors;
            state.materialProductErrorMessage = payload.message;
        },
        resetMaterialProductErrors: (state) => {
            state.materialProductErrors = {};
            state.materialProductErrorMessage = '';
        }
    },
})
// Action creators are generated for each case reducer function
export const { setMaterialProductList, setMaterialProductErrors, resetMaterialProductErrors } = MaterialProductSlice.actions

//selectors
export const storeMaterialProductList = state => state.MaterialProductSlice.materialProductList;
export const storeMaterialProductErrors = state => state.MaterialProductSlice.materialProductErrors;
export const storeMaterialProductErrorMessage = state => state.MaterialProductSlice.materialProductErrorMessage;

export default MaterialProductSlice.reducer

//getMaterialProduct

export function getMaterialProduct(params) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
            }
        };
        return await axios.post(config.API_BASE_URL + config.API_MATERIAL_PRODUCT_LIST, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setMaterialProductList(data))
                } else {
                    dispatch(setMaterialProductErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setMaterialProductErrors(error.response))
            });
    }
}

export function makeStripePaymentForMaterial(params) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL + config.API_MATERIAL_MAKE_STRIPE_PAYMENT, params, options)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                return error;
            });
    }
}

export function makePayPalPaymentForMaterial(params) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL + config.API_MATERIAL_MAKE_PAYPAL_PAYMENT, params, options)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                return error;
            });
    }
}


export function getAllUserOrders(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL + config.API_MATERIAL_GET_ORDER_HISTORY, params, options)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                return error;
            });
    }
}


export function makePickupRequestForMaterial(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL + config.API_MATERIAL_MAKE_PICKUP_REQUEST, params, options)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                return error;
            });
    }
}

export function getMaterialOrderNotesDocument(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL + config.API_MATERIAL_GET_ORDER_NOTES_DOCUMENT, params, options)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                return error;
            });
    }
}

export function getMaterialSingleProduct(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
            }
        };
        return await axios.post(config.API_BASE_URL + config.API_MATERIAL_GET_SINGLE_PRODUCT, params, options)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                return error;
            });
    }
}


export function resetLocalStorageValues() {
    return async dispatch => {
        console.log('Local storage cleared:');
        localStorage.removeItem('appoinmentForm');
        localStorage.removeItem('material_container_price');
        localStorage.removeItem('material_container');
        localStorage.removeItem('selected_material_product');
        localStorage.removeItem('material_product_id');
        localStorage.removeItem('material_category_id');
        localStorage.removeItem('coupon_data');
        localStorage.removeItem('material_appoinmentForm');
        localStorage.removeItem('is_material_order');
        localStorage.removeItem('material_container_name');
        localStorage.removeItem('material_qty');
        localStorage.removeItem('selected_material_category');
        localStorage.removeItem('material_loginFromPaymentSteps');
        localStorage.removeItem('selected_address');
        localStorage.removeItem('final_order_price');
        localStorage.removeItem('material_container_id');
        localStorage.removeItem('material_container');
        localStorage.removeItem('selectedZIPCODE');
        localStorage.removeItem('is_reorder');
        localStorage.removeItem('order_id');
        localStorage.removeItem('new_reorder_container_id');
        
        localStorage.removeItem('container_size');
        localStorage.removeItem('container_id');
        localStorage.removeItem('container_price');
        localStorage.removeItem('garbage_id');
        localStorage.removeItem('payment_on_site_desc');
        localStorage.removeItem('container_name');
        localStorage.removeItem('garbage_desc');

        return true;
    }
}
