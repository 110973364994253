import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'

export default function UserSidebarMenu() {

  const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';

  return (
      <div className="d-flex flex-column flex-shrink-0 p-3 bg-white shadow rounded">
        <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <Link to="/profile" className={`nav-link text-black ${(window.location.pathname === '/profile') ? 'theme-bg' : ''}`} aria-current="page"><i className="fas fa-user"></i> { t('Profile') }</Link>
          {/* <Link to="/saved-address" className={`nav-link text-black mt-2 ${(window.location.pathname === '/saved-address') ? 'theme-bg' : ''}`} aria-current="page"><i className="fas fa-map-marker-alt"></i> { t('Address') }</Link> */}
          <Link to="/orders" className={`nav-link text-black mt-2 ${(window.location.pathname === '/orders') ? 'theme-bg' : ''}`} aria-current="page"><i className="fas fa-clipboard-list"></i> { t('Orders') }</Link>
          
          {(owner_data && owner_data.can_manage_material_module==1) && <Link to="/material-order-history" className={`nav-link text-black mt-2 ${(window.location.pathname === '/material-order-history') ? 'theme-bg' : ''}`} aria-current="page"><i className="fas fa-clipboard-list"></i> { t('Material Orders') }</Link>}

          <Link to="/order-documents" className={`nav-link text-black mt-2 ${(window.location.pathname === '/order-documents') ? 'theme-bg' : ''}`} aria-current="page"><i className="fas fa-folder-open"></i> { t('Order Document') }</Link>

          {(owner_data && owner_data.can_manage_material_module==1) && <Link to="/material-order-documents" className={`nav-link text-black mt-2 ${(window.location.pathname === '/material-order-documents') ? 'theme-bg' : ''}`} aria-current="page"><i className="fas fa-folder-open"></i> { t('Material Order Document') }</Link> }

          <Link to="/change-password" className={`nav-link text-black mt-2 ${(window.location.pathname==='/change-password') ? 'theme-bg' : ''}`} aria-current="page"><i className="fas fa-key"></i> { t('Change Password') }</Link>
        </li>
        </ul>
    </div>
  )
}
