import React, { useEffect } from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import { useSelector, useDispatch } from 'react-redux'
import { getOrderDocuments, storeOrderDocumentsList} from './../Store/Slices/OrderSlice'

import UserSidebarMenu from './../Components/UserSidebarMenu';
import ChildAccordionItem from './../Components/ChildAccordionItem';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';


export default function OrderDocuments() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let order_documents = useSelector(storeOrderDocumentsList);

    useEffect(() => {
        if(localStorage.getItem('skipgireusertoken')=="" || localStorage.getItem('skipgireusertoken')==null){
            navigate('/login')
        }
        dispatch(getOrderDocuments());
    }, [dispatch]);

  return (
    <FrontendLayout>
            <div className="container rounded mt-2 mb-5">
                <div className="row mt-5">
                    <div className='col-md-3 order-md-1'>
                        <UserSidebarMenu />
                    </div>
                    <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 order-md-2">
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <h4 className="text-right">{ t('Order Documents') }</h4>
                            </div>
                            <div className="row">
                                <div className="accordion" id="accordionExample">
                                    {order_documents.map(order_document => (
                                        <div key={order_document.id} className="accordion-item border-0 rounded-lg shadow mt-2">
                                            <h2 className="accordion-header" id={`heading-${order_document.id}`}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collaps${order_document.id}`} aria-expanded="false" aria-controls={`collaps${order_document.id}`}>
                                                <img src="assets/images/images/folderPurple.png" alt='doc-folder' className='me-2'/><strong>{ t('Order') } &nbsp;</strong>  {order_document.order_id}
                                            </button>
                                            </h2>
                                            <div id={`collaps${order_document.id}`} className="accordion-collapse collapse" aria-labelledby={`heading-${order_document.id}`} data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <ChildAccordionItem type="delivery" order_document={order_document} />
                                                    <ChildAccordionItem type="picked_up_notes" order_document={order_document} />
                                                    <ChildAccordionItem type="invoice" order_document={order_document} />
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FrontendLayout>
  )
}
