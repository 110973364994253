import React, { useState, useEffect } from 'react';
import { config } from '../constans';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {makeStripePayment } from './../Store/Slices/PaymentSlice'
import { toast } from 'react-toastify';
import FrontendLayout from '../Layout/FrontendLayout';
import axios from 'axios';
import { t } from 'i18next';
import { makeStripePaymentForMaterial } from '../Store/Slices/MaterialProductSlice';

export default function ThankYou() {

    const dispatch = useDispatch();
    const [payment_method, setPaymentMethod] = useState("");
    const [loadingStripeBtn, setLoadingStripeBtn] = useState(true);
    const [payment, setPayment] = useState({});

    const selectedAddress = JSON.parse(localStorage.getItem('selected_address'));
    const container_id = JSON.parse(localStorage.getItem('container_id'));
    const appoinmentForm = JSON.parse(localStorage.getItem('appoinmentForm'));
    const selectedZIPCODE = JSON.parse(localStorage.getItem('selectedZIPCODE'));


    // Material
    const materialCategory = JSON.parse(localStorage.getItem('selected_material_category'));
    const materialProduct = JSON.parse(localStorage.getItem('selected_material_product'));
    const materialContainer = JSON.parse(localStorage.getItem('material_container'));
    const material_qty = localStorage.getItem('material_qty');

    const is_material_order = localStorage.getItem('is_material_order');

    const coupondata = (localStorage.getItem('coupon_data') !== '' || localStorage.getItem('coupon_data')!==undefined) ? JSON.parse(localStorage.getItem('coupon_data')) : '0';
    
    let isrecorder = localStorage.getItem('is_reorder');
    let order_id = localStorage.getItem('order_id');
    
    useEffect(() => {
        retrivePayment();
    },[]);

    async function retrivePayment(){
        setLoadingStripeBtn(true);
        const url = new URL(window.location);
        const clientSecret = url.searchParams.get('payment_intent');

        await axios.get('https://api.stripe.com/v1/payment_intents/'+clientSecret, {
            
            headers: {
                Authorization: 'Bearer '+config.STRIPE_SECRET,
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then(function (payment) {
            console.log('payment --> ', payment);
            if (payment && payment.data.status === 'succeeded' || payment.data.status === 'processing' || payment.data.status === 'pending') {
                
                setPayment(payment.data);
                setPaymentMethod(payment.data.payment_method_types[0]);
                
                let PaymentRes = {
                    "paymentMethodId":payment.data.payment_method,
                    "currency": payment.data.currency,
                    "captureMethod": payment.data.capture_method,
                    "status": payment.data.status,
                    "amount": payment.data.amount,
                    "confirmationMethod": payment.data.confirmation_method,
                    "livemode": payment.data.livemode,
                    "clientSecret": payment.data.client_secret,
                    "created": payment.data.created,
                    "receiptEmail": payment.data.receipt_email,
                    "canceledAt": payment.data.canceled_at,
                    "nextAction": payment.data.next_action,
                    "id": payment.data.id
                };

                if(is_material_order){ // Material Order
                    let paymentApiParams = {

                        owner_id: localStorage.getItem('MAIN_OWNER_ID'),
                        address_id: selectedAddress.id,
                        category_id: materialCategory.id,
                        product_id: materialProduct.id,
                        product_type_id: materialContainer.id,
                        qty: material_qty,
                        delivery_date: Math.round(+new Date(appoinmentForm.delivery_on) / 1000),
                        delivery_time: appoinmentForm.delivery_time,
                        pincode: selectedZIPCODE[0].name,
                        payment_type: payment.data.payment_method_types[0],
                        order_type: 'new_order',
                        coupon_id: (coupondata) ? coupondata.id : '0',
                        placement_option: appoinmentForm.placement_option,
                        response_data: JSON.stringify(PaymentRes),
                    };
                    
                    if(materialContainer.container_type == 'container'){
                        paymentApiParams = {...paymentApiParams, pickup_date: Math.round(+new Date(appoinmentForm.pickup_on)/1000),
                        pickup_time: appoinmentForm.delivery_time};
                    }
    
                    dispatch(makeStripePaymentForMaterial(paymentApiParams)).then((res) => {
                    
                        if (res.status === 200) {
                            setLoadingStripeBtn(false);
                            
                            toast.success(res.message);
                            localStorage.removeItem('appoinmentForm');
                            localStorage.removeItem('material_container_price');
                            localStorage.removeItem('material_container');
                            localStorage.removeItem('selected_material_product');
                            localStorage.removeItem('material_product_id');
                            localStorage.removeItem('vat_tax');
                            localStorage.removeItem('material_category_id');
                            localStorage.removeItem('skiphire_guest_user');
                            localStorage.removeItem('coupon_data');
                            localStorage.removeItem('material_appoinmentForm');
                            localStorage.removeItem('is_material_order');
                            localStorage.removeItem('material_container_name');
                            localStorage.removeItem('material_qty');
                            localStorage.removeItem('selected_material_category');
                            localStorage.removeItem('material_loginFromPaymentSteps');
                            localStorage.removeItem('selected_address');
                            localStorage.removeItem('final_order_price');
                            localStorage.removeItem('material_container_id');
                            localStorage.removeItem('selectedZIPCODE');
                            localStorage.removeItem('is_reorder');
                            localStorage.removeItem('order_id');
                            localStorage.removeItem('new_reorder_container_id');
                            // navigate('/');

                        } else {
                            toast.error(res.message);
                        }
                    });
                }else{ // COntainer order
                    let paymentApiParams = {
                        owner_id: localStorage.getItem('MAIN_OWNER_ID'),
                        address_id: selectedAddress.id,
                        container_id: container_id,
                        pickup_date: Math.round(+new Date(appoinmentForm.pickup_on)/1000),
                        pickup_time: appoinmentForm.delivery_time,
                        delivery_date: Math.round(+new Date(appoinmentForm.delivery_on) / 1000),
                        delivery_time: appoinmentForm.delivery_time,
                        pincode: selectedZIPCODE[0].name,
                        payment_type: payment.data.payment_method_types[0],
                        order_type: 'new_order',
                        garbage_id: localStorage.getItem('garbage_id'),
                        response_data: JSON.stringify(PaymentRes),
                        coupon_id: (coupondata) ? coupondata.id : '0',
                        placement_option: appoinmentForm.placement_option,
                    };
                    
                    if (isrecorder === '1' && order_id >= 0) {
                        paymentApiParams = {...paymentApiParams, order_type:'swap_order', order_id:order_id}
                    }
    
                    dispatch(makeStripePayment(paymentApiParams)).then((res) => {
                    
                        if (res.status === 200) {
                            setLoadingStripeBtn(false);
                            
                            // setPaymentMethod(paymentIntent.payment_method_types[0]);
    
                            toast.success(res.message);
                            localStorage.removeItem('appoinmentForm');
                            localStorage.removeItem('material_container_price');
                            localStorage.removeItem('material_container');
                            localStorage.removeItem('selected_material_product');
                            localStorage.removeItem('material_product_id');
                            localStorage.removeItem('vat_tax');
                            localStorage.removeItem('material_category_id');
                            localStorage.removeItem('skiphire_guest_user');
                            localStorage.removeItem('coupon_data');
                            localStorage.removeItem('material_appoinmentForm');
                            localStorage.removeItem('is_material_order');
                            localStorage.removeItem('material_container_name');
                            localStorage.removeItem('material_qty');
                            localStorage.removeItem('selected_material_category');
                            localStorage.removeItem('material_loginFromPaymentSteps');
                            localStorage.removeItem('selected_address');
                            localStorage.removeItem('final_order_price');
                            localStorage.removeItem('material_container_id');
                            localStorage.removeItem('selectedZIPCODE');
                            localStorage.removeItem('is_reorder');
                            localStorage.removeItem('order_id');
                            localStorage.removeItem('new_reorder_container_id');
                        } else {
                            toast.error(res.message);
                        }
                    });
                }
            }else{
                setLoadingStripeBtn(false);
                setPayment("");
                toast.error(t("Something went wrong! please try again later."))
            }
            
        }).catch(function (error) {
            setLoadingStripeBtn(false);
            toast.error(error.message);
        });
    }

    return(
   
        <FrontendLayout>
            { (() => {
                if(loadingStripeBtn === true) {
                    return (
                        <main className="container">
                            <div className="bg-light p-5 rounded">
                            <h5> <div className="spinner-border me-2" style={{height:'25px', width:'25px'}} role="status"></div> {t("Please Wait we are processing your order don't press back or refresh Page")} .....</h5>
                            </div>
                        </main>
                    )
                } else if(payment !== "" && loadingStripeBtn === false) {
                    return (
                        <main className="container">
                            <div className="bg-light p-5 rounded">
                                <h1>{t('Thank You')}</h1>
                                <p className="lead"> {t('Thank you for your Order')} </p>
                                <p><b>{t('Payment ID')}:</b> {payment.id}</p>
                                <p><b>{t('Amount')}:</b> {payment.amount/100}</p>
                                <p><b>{t('Payment Method')}:</b> {payment_method}</p>
                                <p><b>{t('Status')}:</b> {payment.status}</p>
                                <Link className="btn btn-lg btn-primary" to="/orders" role="button">{t('View Orders')} »</Link>
                            </div>
                        </main>
                    )
                }else{
                    return (
                        <main className="container">
                            <div className="bg-light p-5 rounded">
                                <h1>{t('Back to home')}</h1>
                                <Link className="btn btn-lg btn-primary" to="/" role="button">{t('Back')}</Link>
                            </div>
                        </main>
                    )
                }
                
            }) ()}
        </FrontendLayout>  
        
    );
}