import React, { useEffect } from 'react'
import TypeOfWasteCard from './TypeOfWasteCard'
import { getGarbageTypeList, storeGarbageTypeList } from './../Store/Slices/GarbageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next';
import $ from 'jquery'

export default function TypesOfWaste() {
  let garbageTypes = useSelector(storeGarbageTypeList);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGarbageTypeList(localStorage.getItem('MAIN_OWNER_ID')));
  }, [])
  
  const handleClick = (garbage_description, name) => {
    $('.modal-body').html(garbage_description);
    $('.modal-title').html(name);
  }

  return (
    <section className="container ">
        <div className="row">
            <div className="col-12">
                <h2>{ t('We dispose of this for you:') }</h2>
              </div>
            { garbageTypes.map(garbage_type => (
                <div className="col-md-6" key={garbage_type.id}>
                    <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative" style={{backgroundColor: garbage_type.color}}>
                    <div className="col-auto d-lg-block">
                        <img src={garbage_type.garbage_image} className="bd-placeholder-img rounded-start" width="160" height="200" alt={garbage_type.name} />
                        </div>
                        <div className="col d-flex flex-column">
                            <div className="card-body">
                                <svg onClick={() => handleClick(garbage_type.garbage_description, garbage_type.name)} data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="float-end" style={{cursor:'pointer'}} stroke="currentColor" width={35}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>

                                <h5 className="card-title">{ (garbage_type.name.length > 25) ? garbage_type.name.substring(0,25)+'...' : garbage_type.name}
                                </h5> 
                                <p className="card-text" >
                                    {(garbage_type.garbage_description.length > 185) ? garbage_type.garbage_description.substring(0, 185).replace(/[\r\n]/gm, '') + '...' : garbage_type.garbage_description.replace(/[\r\n]/gm, '')}
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>

                
            ))}   
        </div>
        
{/* <div className="col-md-6 col-lg-6 col-sm-6 col-sx-12" key={garbage_type.id}>
                    <div className='card mb-3' style={{backgroundColor: garbage_type.color}}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src={garbage_type.garbage_image} className="bd-placeholder-img rounded-start" alt={garbage_type.name} height="240" />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <svg onClick={() => handleClick(garbage_type.garbage_description, garbage_type.name)} data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="float-end" style={{cursor:'pointer'}} stroke="currentColor" width={35}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                    </svg>

                                    <h5 className="card-title">{ (garbage_type.name.length > 25) ? garbage_type.name.substring(0,25)+'...' : garbage_type.name}
                                    </h5>
                                        
                                    <p className="card-text" >
                                        {(garbage_type.garbage_description.length > 185) ? garbage_type.garbage_description.substring(0, 185).replace(/[\r\n]/gm, '') + '...' : garbage_type.garbage_description.replace(/[\r\n]/gm, '')}
                                    </p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"></h5>
                        <button type="button" className="btn-close closeThisPopup" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
