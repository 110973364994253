import React, { useState } from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import { useSelector, useDispatch } from 'react-redux'
import { changePassword, storeAuthErrors} from './../Store/Slices/UserAuthSlice'
import { toast } from 'react-toastify';
import UserSidebarMenu from './../Components/UserSidebarMenu';
import { t } from 'i18next';
import MainButton from '../Components/MainButton'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
    // Define consts
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let errors = useSelector(storeAuthErrors);
    const [changePasswordForm, setchangePasswordForm] = useState({
        old_password:'',
        new_password:'',
        confirm_password:'',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(localStorage.getItem('skipgireusertoken')=="" || localStorage.getItem('skipgireusertoken')==null){
            navigate('/login')
        }
    }, [])
    

    // Handle Change password button click (Api call)
    const handleChangePasswordClick = (event) => {
        event.preventDefault()
        setLoading(true);
        dispatch(changePassword(changePasswordForm)).then((data) => {
            if (data.status === 200) {
                toast.success(data.message)
                setchangePasswordForm({
                    old_password: '',
                    new_password: '',
                    confirm_password: '',
                });
            }
            setLoading(false);
        });
    }

    // Handle input change event.
    const handleInputChange = (e) => {
        setchangePasswordForm({ ...changePasswordForm, [e.target.id]: e.target.value });
    }

    return (
        <FrontendLayout>
            <div className="container rounded mt-2 mb-5">
                <div className="row mt-5">
                    <div className='col-md-3 order-md-1 mb-3'>
                        <UserSidebarMenu />
                    </div>
                    <div className="col-md-9 card col-lg-9 col-sm-12 col-sx-12 order-md-2 border-right">
                        <div className="p-3">
                            <div className="d-flex mt-3 justify-content-between align-items-center mb-1">
                                <h5 className="text-right">{ t('Change Password') }</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="labels">{ t('Current Password') }</label>
                                    <input type="password" className={`form-control h-50 ${errors.hasOwnProperty('old_password') && 'is-invalid'}`} id='old_password' onChange={handleInputChange} value={changePasswordForm.old_password} />
                                    {errors.hasOwnProperty('old_password') && <div className="invalid-feedback">{errors.old_password}</div>}
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('New Password') }</label>
                                    <input type="password" className={`form-control h-50 ${errors.hasOwnProperty('new_password') && 'is-invalid'}`} onChange={handleInputChange}  id="new_password" value={changePasswordForm.new_password} />
                                    {errors.hasOwnProperty('new_password') && <div className="invalid-feedback">{errors.new_password}</div>}
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('Confirm Password') }</label>
                                    <input type="password" className={`form-control h-50 ${errors.hasOwnProperty('confirm_password') && 'is-invalid'}`} onChange={handleInputChange} id="confirm_password" value={changePasswordForm.confirm_password}/>
                                    {errors.hasOwnProperty('confirm_password') && <div className="invalid-feedback">{errors.confirm_password}</div>}
                                </div>
                            </div>
                            <div className="mt-1 text-left">
                                <MainButton title={ t('Change Password') } loading={loading} handleClick={ handleChangePasswordClick } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FrontendLayout>
    )
}
