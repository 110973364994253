import React, { useEffect } from 'react'
import $ from 'jquery'
import { t } from 'i18next';
import { useState } from 'react';
import { numberFormat } from './NumberFormat';

export default function MaterialContainerCard(props) {

    const ownerDetails = JSON.parse(localStorage.getItem('owner_data'));
    const [qty, setQty] = useState(1);
    const [totalPrice, settotalPrice] = useState(props.container.final_price);
    const mainNetPrice = (props.container.price_per_ton) ? props.container.price_per_ton : props.container.price_per_cbm;
    const [totalNetPrice, settotalNetPrice] = useState(mainNetPrice);

    const handleQtyChange = (type, container) => {
        let newQty = qty;
        if(type == 'increase'){
            if(qty < container.max_can_order){
                newQty = qty+1;
            }
        }else{
            if(qty > 1){
                newQty = qty-1;
            }
        }

        setQty(newQty);
        localStorage.setItem('material_qty', newQty);

        // Calculate net price
        let tempPrice = (container.price_per_ton) ? container.price_per_ton.replace(',','.') : container.price_per_cbm.replace(',','.');
        settotalNetPrice((parseFloat(tempPrice)*newQty).toFixed(2))

        // Calculate final price
        let tempFinalPrice = parseFloat(container.final_price.replace(',','.'));
        settotalPrice((parseFloat(tempFinalPrice)*newQty).toFixed(2))

    }

    useEffect(() => {
        console.log('props.container', props.container);
        localStorage.setItem('material_qty', qty);
    }, [])
    

  return (
    <div className={ (props.selectedContainer && props.selectedContainer.id===props.container.id) ? "card mb-3 theme-light-yellow border border-secondary border-2" :  "card mb-3 theme-light-yellow" } id="list-container">
        <div className="row g-0">
            <div className="col-md-4 col-lg-4 col-sm-12 col-sx-12">
                  <img style={{marginTop:'35%'}} src={ (props.container.container_image_url) ? props.container.container_image_url : '/assets/images/images/gips.jpeg' } className="w-100 h-auto" />
            </div>
            <div className="col-md-8 col-lg-8 col-sn-12 col-sx-12">
                <div className="card-body">
                    <h5 className="card-title">{props.productName}</h5>
                    <p className='card-text'>{props.container.container_title}</p>
                    <h4>{props.container.final_price} € / {(props.container.container_type=='bigbag' ? 'Big Bag' : (props.container.price_per_ton) ? 't' : 'cbm')}</h4>
                    <h5>({mainNetPrice} €/{t('Net')})</h5>
                    <h5>Plus: <p className="card-text" dangerouslySetInnerHTML={{ __html: props.container.container_description }}></p></h5>
                    <h5>Max: {props.container.max_can_order} {(props.container.container_type=='bigbag' ? 'Big Bag' : 'Tonnen')}</h5>

                    {/* qty changer */}
                    <div className='col-md-6'>
                        <div className="input-group">
                            <div className="input-group-prepend decrease-qty" data-id={props.container.id}>
                                <span className="input-group-text" id="inputGroupPrepend" onClick={() => handleQtyChange('decrease', props.container)}>-</span>
                            </div>
                            <span className="form-control">{qty}</span>
                            <div className="input-group-append increase-qty" data-id={props.container.id}>
                                <span className="input-group-text" id="inputGroupPrepend" onClick={() => handleQtyChange('increase', props.container)}>+</span>
                            </div>
                        </div>
                    </div>

                    {qty>0 && <p className="card-text"><small className="text-muted">In Total : {totalPrice} € / ({totalNetPrice} €/{t('Net')} )</small></p>}
                </div>
            </div>
        </div>
    </div>
  )
}
