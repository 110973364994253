import React from 'react'
import FrontendLayout from '../../Layout/FrontendLayout'
import UserSidebarMenu from '../../Components/UserSidebarMenu'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAllUserOrders, getMaterialSingleProduct, makePickupRequestForMaterial } from '../../Store/Slices/MaterialProductSlice'
import { useState } from 'react'
import { toast } from 'react-toastify'
import LoadingSpinner from '../../Components/LoadingSpinner'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import { checkPostcode } from '../../Store/Slices/GarbageSlice'
import { useNavigate } from 'react-router-dom'

export default function MaterialOrderHistory() {

    // Heleprs
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // States / Veriables
    const [orderList, setOrderList] = useState([]);
    const [PickupRequestBtnloading, setPickupRequestBtnloading] = useState(false);
    const [pickupRequestPickupDate, setPickupRequestPickupDate] = useState('');
    const [pickupRequestErrors, setPickupRequestErrors] = useState([]);
    const [pickupdate, setPickupDate] = useState("whole_day");

    useEffect(() => {
        if(localStorage.getItem('skipgireusertoken')=="" || localStorage.getItem('skipgireusertoken')==null){
            navigate('/login')
        }
        getOrders()
    }, [])
    
    const getOrders = () => {
        dispatch(getAllUserOrders()).then((orders)=>{
            if(orders.status == 200){
                setOrderList(orders.data)
            }else{
                toast.error(orders.message);
            }
        });
    }

    const handlePickupRequestClick = (orderDetails) => {
        setPickupRequestPickupDate('');
        setPickupRequestErrors([]);
        $('#PickupDateLabel').html('ORDER ID ' + orderDetails.order_id);
        $('#pickupRequestOrderID').val(orderDetails.id);
    }

    const handlePickupDateInputChange = (date) => {
        setPickupRequestPickupDate(date)
    }

    const handleAppoinmentFormTimeInput = (event, field) => {
        setPickupDate(event.target.value);
    }


    const handlePickupRequestBtnClick = async () => {
        setPickupRequestBtnloading(true);
        const PickupRequestParams = {
            order_id: $('#pickupRequestOrderID').val(),
            pickup_date: Math.round(+new Date(pickupRequestPickupDate)/1000),
            pickup_time: pickupdate,
        }
        console.log('PickupRequestParams', PickupRequestParams);
        dispatch(makePickupRequestForMaterial(PickupRequestParams)).then((response) => {
            if (response.status === 0) {
                setPickupRequestErrors(response.message);
            } else {
                setPickupRequestErrors([]);
                toast.success(response.message);
                getOrders();
                $('#pickupRequestCloseBtn').trigger('click');
            }
            setPickupRequestBtnloading(false);
        })
    }

    // Hanlde New Reorder Btn Click
    const handleNewReorderBtnClick = async (orderDetails) => {
        let params = { owner_id: localStorage.getItem('MAIN_OWNER_ID'), postcode: orderDetails.pincode };
        dispatch(checkPostcode(params)).then(() => {
            console.log('PARA ', params);
            dispatch(getMaterialSingleProduct({...params, product_id:orderDetails.product_id})).then((data)=>{
                if(data.status == 200){
                    localStorage.setItem('selected_material_category', JSON.stringify(orderDetails.material_category));
                    localStorage.setItem('selected_material_product', JSON.stringify(data.data[0]));
                    navigate('/material-products-containers/');
                }else{
                    toast.error(t('Something went wrong! please try again later.'))
                }
            })
        });
    }

    const getFullAddress = (address) => {
        let add = address.address.delivery_street;
        add += (address.address.delivery_address_2) ? ', '+address.address.delivery_address_2 : '';
        add += (address.address.delivery_city) ? ', '+address.address.delivery_city : '';
        add += (address.address.delivery_zip_code) ? ', ' + address.address.delivery_zip_code : '';
        return add;
    }

  return (
    <FrontendLayout>
        <div className="container rounded mt-2 mb-5">
            <div className="row mt-5">
                <div className='col-md-3 order-md-1'>
                    <UserSidebarMenu />
                </div>
                <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 order-md-2">
                    <div className="p-3">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <h4 className="text-right">{ t('Material Orders') }</h4>
                        </div>
                        <div className="row">
                            {/* List of active orders */}
                            {orderList.filter(item => item.status!=='picked-up').map((orderdetails, index) => (
                            <div className="card border-0 mt-3  shadow rounded theme-light-yellow" key={index}>
                                <div className="card-body">
                                    <p className="card-title"><strong>{ t('Order no') }.:</strong>  {orderdetails.order_id}</p>
                                    
                                    <h4 className="card-text">{ orderdetails.product_name }<span className='float-end'>{orderdetails.total_amount.replace('.',',')+' €'}</span></h4>
                                    
                                    <h4 className="card-text">{orderdetails.material_category.title}</h4>
                                    
                                    <p className="card-text"><strong>{t('Qty')}:</strong> {orderdetails.qty}</p>
                        
                                    <p className="card-text"><strong>{t('Container type')}:</strong> {(orderdetails.material_container_type) ? orderdetails.material_container_type.title : ''}</p>
                                    
                                    {(orderdetails.material_container_type && orderdetails.material_container_type.type == 'container') && <p className="card-text"><strong>{t('Pickup date')}:</strong> {orderdetails.pickup_date} {orderdetails.pickup_time}</p>}
                                    
                                    <p className="card-text"><strong>{t('Delivery date')}:</strong> {orderdetails.delivery_date}  {orderdetails.delivery_time}</p>
                                    
                                    <p className="card-text"><strong>{ t('Addresss') }:</strong> {getFullAddress(orderdetails)}</p>
                                    
                                    { (() => {
                                        if(orderdetails.status === 'pending') {
                                            return (
                                                <p className="card-text"><strong>{t('Status')}:</strong> <span className='badge p-2 bg-danger'>{ t('New Order') }</span></p>
                                            )
                                        } else if(orderdetails.status === 'in-delivery'){
                                            return (
                                                <p className="card-text"><strong>{t('Status')}:</strong> <span className='badge p-2 bg-warning'>{ t('Assigned to driver') }</span></p>
                                            )
                                        } else if(orderdetails.status === 'delivered'){
                                            return (
                                                <p className="card-text"><strong>{t('Status')}:</strong> <span className='badge p-2 bg-success'>{ t('Delivered') }</span></p>
                                            )
                                        } else if(orderdetails.status === 'in-pickup'){
                                            return (
                                                <p className="card-text"><strong>{t('Status')}:</strong> <span className='badge p-2 bg-info'>{ t('Assigned to driver for pickup') }</span></p>
                                            )
                                        }
                                    }) ()}
                                    
                                    {(orderdetails.status === 'delivered' && orderdetails.material_container_type && orderdetails.material_container_type.type == 'container') && <>
                                    <a href="#" onClick={() => handlePickupRequestClick(orderdetails)} id={orderdetails.id} className="btn border border-dark border-1" data-bs-toggle="modal" data-bs-target="#PickupDate"><img height={25} className="me-2" src="assets/images/images/roundArrowLeft.png" />{ t('Pickup request') }</a></>}
                                
                                </div>
                            </div>
                            ))}
                        </div>
                        <div className="d-flex mt-3 justify-content-between align-items-center mb-1">
                            <h4 className="text-right">{ t('Archive') }</h4>
                        </div>
                        <div className="row">
                            {/* List of archive orders */}
                            {orderList.filter(item => item.status==='picked-up').map(orderdetails => (
                                <div key={orderdetails.id} className="card border-0 mt-3  shadow rounded theme-light-cyan">
                                    <div className="card-body">
                                        <p className="card-title"><strong>{ t('Order no') }.:</strong>  {orderdetails.order_id}</p>

                                        <h4 className="card-text">{ orderdetails.product_name }<span className='float-end'>{orderdetails.total_amount+' €'}</span></h4>
                                    
                                        <h4 className="card-text">{orderdetails.material_category.title}</h4>
                                        
                                        <p className="card-text"><strong>{t('Qty')}:</strong> {orderdetails.qty}</p>
                            
                                        <p className="card-text"><strong>{t('Container type')}:</strong> {(orderdetails.material_container_type) ? orderdetails.material_container_type.title : ''}</p>
                                        
                                        {(orderdetails.material_container_type && orderdetails.material_container_type.type == 'container') && <p className="card-text"><strong>{t('Pickup date')}:</strong> {orderdetails.pickup_date} {orderdetails.pickup_time}</p>}
                                        
                                        <p className="card-text"><strong>{t('Delivery date')}:</strong> {orderdetails.delivery_date}  {orderdetails.delivery_time}</p>
                                        
                                        <p className="card-text"><strong>{ t('Addresss') }:</strong> {getFullAddress(orderdetails)}</p>

                                        <p className="card-text"><strong>{ t('Status') }:</strong> <span className='badge bg-success p-2'>{ t('Picked Up') }</span></p>

                                        <a href="#" onClick={() => handleNewReorderBtnClick(orderdetails)} className="btn border border-dark border-1"><img height={25} className="me-2" src="assets/images/images/roundArrowRight.png" />{ t('Reaorder') }</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>


        {/* Modal for pickup */}
        <div className="modal fade" data-bs-backdrop="static" id="PickupDate" tabIndex="-1" role="dialog" aria-labelledby="PickupDateLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                        <h5 className="modal-title" id="PickupDateLabel">{ t('Pickup date') }</h5>
                    <i id='pickupRequestCloseBtn' className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </i>
                </div>
                    <div className="modal-body">
                        {pickupRequestErrors.length > 0 ?
                            <ul className='col-12 mt-3 alert alert-danger p-2' style={{listStyle:'none'}}>
                                {pickupRequestErrors.map((err, index) => (<li key={index}>{err}</li>))}
                            </ul>
                            : ''}
                        <div className='control-group col-12 mt-3'>
                            <label className='form-label'><strong>{ t('Pickup date') }</strong></label>
                            <input type="hidden" id="pickupRequestOrderID"/>
                            <DatePicker
                                className='form-control'
                                selected={pickupRequestPickupDate}
                                minDate={new Date()}
                                onChange={(date) => handlePickupDateInputChange(date)}
                                // showTimeSelect
                                dateFormat="dd.MM.yyyy"
                                name="delivery_on"
                            />

                            <label className='form-label mt-2'><strong>{ t('Delivery Time') }</strong></label>
                            <div className="form-check">
                                <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='whole_day' type="radio" name="delivery_on_time" defaultChecked="whole_day" id="delivery_on_time_whole_day"  />
                                <label className="form-check-label" htmlFor="delivery_on_time_whole_day">{ t('Whole day (from 8:00 h till 16:00 h)') }</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='mornings' type="radio" name="delivery_on_time" id="delivery_on_time_mornings"/>
                                <label className="form-check-label" htmlFor="delivery_on_time_mornings">{ t("Mornings (from 8:00 h till 12:30 h)") }</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" onChange={(e) => handleAppoinmentFormTimeInput(e, 'delivery_time')} value='afternoon' type="radio" name="delivery_on_time" id="delivery_on_time_afternoon"/>
                                <label className="form-check-label" htmlFor="delivery_on_time_afternoon">{ t("Afternoon (from 12:30 h till 16:00 h)") }</label>
                            </div>
                        </div>
                </div>
                <div className="modal-footer">
                        <button type="button" className="btn border border-1 border-dark" data-bs-dismiss="modal">{ t('Close') }</button>
                        <button onClick={handlePickupRequestBtnClick} type="button" className="btn border border-1 border-dark">
                            {PickupRequestBtnloading ? <LoadingSpinner /> : <img height={25} className="me-2" alt='arrow-left' src="assets/images/images/roundArrowLeft.png" />}
                            { t('Make Pickup Request') }
                        </button>
                </div>
                </div>
            </div>
        </div>
    </FrontendLayout>
  )
}
