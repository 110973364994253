import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { config, CheckAuth } from './../../constans';

export const BlogSlice = createSlice({
    name: 'BlogSlice',
    initialState: {
          allBlogs: [],
          singleBlog: {},
          blogErrors: [],
    },
    reducers: {
        setallBlogs: (state, { payload }) => {
            state.allBlogs = payload.data;
            state.blogErrors = [];
        },
        setsingleBlog: (state, { payload }) => {
            state.singleBlog = payload.data;
            state.blogErrors = [];
        },
        setblogErrors: (state, { payload }) => {
            state.blogErrors = payload.errors;
        },
    },
})
  
// Action creators are generated for each case reducer function
export const {
    setallBlogs,
    setsingleBlog,
    setblogErrors,
} = BlogSlice.actions
  
// Selectors
export const storeAllBlogs = state => state.BlogSlice.allBlogs;
export const storeSingleBlog = state => state.BlogSlice.singleBlog;
export const storeBlogErrors = state => state.BlogSlice.blogErrors;

  
export default BlogSlice.reducer
  
  // API Function
export function getAllBlogs(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_GET_BLOGS_OFFER, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setallBlogs(data))
                } else {
                    dispatch(setblogErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setblogErrors(error.response))
            });
    }
}

export function getSingleBlog(id) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_GET_BLOG_DETAIL, {blog_id:id}, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setsingleBlog(data))
                } else {
                    dispatch(setblogErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setblogErrors(error.response))
            });
    }
}