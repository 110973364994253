import React, { useState } from 'react'
import LoadingSpinner from './LoadingSpinner';
import { t } from 'i18next';
import axios from 'axios'
import { config } from '../constans';
import StatusMessages, { useMessages } from './StatusMessages';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {makeStripePayment } from './../Store/Slices/PaymentSlice'
import { toast } from 'react-toastify';
import { numberFormat } from '../Components/NumberFormat';
import { makeStripePaymentForMaterial } from '../Store/Slices/MaterialProductSlice';

export default function PaymentOnSite() {

    const dispatch = useDispatch();
    const [loadingPaymentOnSiteBtn, setLoadingPaymentOnSiteBtn] = useState(false);
    const [messages, addMessage] = useMessages();
    const [payment_method, addPaymentMethod] = useState("");
    const [total_amount, SetTotalAmount] = useState();
    const [payment_on_site_success, setPaymentOnSiteSuccess] = useState(false);

    const selectedAddress = JSON.parse(localStorage.getItem('selected_address'));
    const container_id = JSON.parse(localStorage.getItem('container_id'));
    const appoinmentForm = JSON.parse(localStorage.getItem('appoinmentForm'));
    const selectedZIPCODE = JSON.parse(localStorage.getItem('selectedZIPCODE'));

    // Material
    const materialCategory = JSON.parse(localStorage.getItem('selected_material_category'));
    const materialProduct = JSON.parse(localStorage.getItem('selected_material_product'));
    const materialContainer = JSON.parse(localStorage.getItem('material_container'));
    const material_qty = localStorage.getItem('material_qty');

    const is_material_order = localStorage.getItem('is_material_order');

    const coupondata = (localStorage.getItem('coupon_data') !== '' || localStorage.getItem('coupon_data')!==undefined) ? JSON.parse(localStorage.getItem('coupon_data')) : '0';
    
    let isrecorder = localStorage.getItem('is_reorder');
    let order_id = localStorage.getItem('order_id');

    const SubmitPaymentOnSite = (event) => {
        setLoadingPaymentOnSiteBtn(true);
        event.preventDefault();

        addMessage("Please Wait we are processing your order don't press back or refresh Page.....");
        
        if(is_material_order){ // Material Order
            let paymentApiParams = {

                owner_id: localStorage.getItem('MAIN_OWNER_ID'),
                address_id: selectedAddress.id,
                category_id: materialCategory.id,
                product_id: materialProduct.id,
                product_type_id: materialContainer.id,
                qty: material_qty,
                delivery_date: Math.round(+new Date(appoinmentForm.delivery_on) / 1000),
                delivery_time: appoinmentForm.delivery_time,
                pincode: selectedZIPCODE[0].name,
                payment_type: 'payment_on_site',
                order_type: 'new_order',
                coupon_id: (coupondata) ? coupondata.id : '0',
                placement_option: appoinmentForm.placement_option,
            };
            
            if(materialContainer.container_type == 'container'){
                paymentApiParams = {...paymentApiParams, pickup_date: Math.round(+new Date(appoinmentForm.pickup_on)/1000),
                pickup_time: appoinmentForm.delivery_time};
            }

            dispatch(makeStripePaymentForMaterial(paymentApiParams)).then((res) => {
            
                if (res.status === 200) {
                    toast.success(res.message);
                    addPaymentMethod(res.data.payment_method);
                    SetTotalAmount(res.data.total_amount);
    
                    setPaymentOnSiteSuccess(true);

                    localStorage.removeItem('appoinmentForm');
                    localStorage.removeItem('material_container_price');
                    localStorage.removeItem('material_container');
                    localStorage.removeItem('selected_material_product');
                    localStorage.removeItem('material_product_id');
                    localStorage.removeItem('vat_tax');
                    localStorage.removeItem('material_category_id');
                    localStorage.removeItem('skiphire_guest_user');
                    localStorage.removeItem('coupon_data');
                    localStorage.removeItem('material_appoinmentForm');
                    localStorage.removeItem('is_material_order');
                    localStorage.removeItem('material_container_name');
                    localStorage.removeItem('material_qty');
                    localStorage.removeItem('selected_material_category');
                    localStorage.removeItem('material_loginFromPaymentSteps');
                    localStorage.removeItem('selected_address');
                    localStorage.removeItem('final_order_price');
                    localStorage.removeItem('material_container_id');
                    localStorage.removeItem('selectedZIPCODE');
                    localStorage.removeItem('is_reorder');
                    localStorage.removeItem('order_id');
                    localStorage.removeItem('new_reorder_container_id');
                    // navigate('/');

                } else {
                    toast.error(res.message);
                }
                setLoadingPaymentOnSiteBtn(false);
            });
        }else{
            let paymentApiParams = {
                owner_id: localStorage.getItem('MAIN_OWNER_ID'),
                address_id: selectedAddress.id,
                container_id: container_id,
                garbage_id: localStorage.getItem('garbage_id'),
                pickup_date: Math.round(+new Date(appoinmentForm.pickup_on)/1000),
                delivery_date: Math.round(+new Date(appoinmentForm.delivery_on) / 1000),
                pickup_time: appoinmentForm.delivery_time,
                delivery_time: appoinmentForm.delivery_time,
                // payment_method: result.paymentMethod.id,
                pincode: selectedZIPCODE[0].name,
                payment_type: 'payment_on_site',
                order_type: 'new_order',
                coupon_id: (coupondata) ? coupondata.id : '0',
                placement_option: appoinmentForm.placement_option,
            };
    
            if (isrecorder === '1' && order_id >= 0) {
                paymentApiParams = {...paymentApiParams, order_type:'swap_order', order_id:order_id}
            }
    
            dispatch(makeStripePayment(paymentApiParams)).then((res) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    addPaymentMethod(res.data.payment_method);
                    SetTotalAmount(res.data.total_amount);
    
                    setPaymentOnSiteSuccess(true);
    
                    localStorage.removeItem('appoinmentForm');
                    localStorage.removeItem('material_container_price');
                    localStorage.removeItem('material_container');
                    localStorage.removeItem('selected_material_product');
                    localStorage.removeItem('material_product_id');
                    localStorage.removeItem('vat_tax');
                    localStorage.removeItem('material_category_id');
                    localStorage.removeItem('skiphire_guest_user');
                    localStorage.removeItem('coupon_data');
                    localStorage.removeItem('material_appoinmentForm');
                    localStorage.removeItem('is_material_order');
                    localStorage.removeItem('material_container_name');
                    localStorage.removeItem('material_qty');
                    localStorage.removeItem('selected_material_category');
                    localStorage.removeItem('material_loginFromPaymentSteps');
                    localStorage.removeItem('selected_address');
                    localStorage.removeItem('final_order_price');
                    localStorage.removeItem('material_container_id');
                    localStorage.removeItem('selectedZIPCODE');
                    localStorage.removeItem('is_reorder');
                    localStorage.removeItem('order_id');
                    localStorage.removeItem('new_reorder_container_id');
    
                } else {
                    toast.error(res.message);
                }
                setLoadingPaymentOnSiteBtn(false);
            });
        }
    }

    return (
    <div>   
        
        { (() => {
            if(loadingPaymentOnSiteBtn === true) {
                return (<StatusMessages messages={messages} />)
            } else if(payment_method !== "" && loadingPaymentOnSiteBtn === false) {
                
                return (
                    <div className="bg-light rounded">
                        <h1>{t('Thank You')}</h1>
                        <p className="lead"> {t('Thank you for your Order')} </p>
                        <p><b>{t('Total Amount')}:</b> { numberFormat(total_amount)}</p>
                        <p><b>{t('Payment Method')}:</b> {t('Payment On Site')}</p>
                        <Link className="btn btn-lg btn-primary" to="/orders" role="button">{t('View Orders')} »</Link>
                    </div>
                )
            }
            
        }) ()}

        { payment_on_site_success === false &&
            <form onSubmit={SubmitPaymentOnSite}>
                <p>{t('Payment On Site')}</p>
                <h5>{(localStorage.getItem('payment_on_site_desc') && localStorage.getItem('payment_on_site_desc')!='null') ? localStorage.getItem('payment_on_site_desc') : '-'}</h5>

                <div className="col-md-6 mt-3">
                    <button className="btn theme-btn mt-2 rounded-pill" disabled={loadingPaymentOnSiteBtn}>{loadingPaymentOnSiteBtn && <LoadingSpinner />} { t('Submit') }</button>
                </div>
            </form>
        }
        
    </div>
    )
}
