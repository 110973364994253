import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { storeUser, Logout } from './../Store/Slices/UserAuthSlice'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetAllData } from './../Store/Slices/UserAuthSlice';
import { Helmet } from 'react-helmet';
import { resetLocalStorageValues } from '../Store/Slices/MaterialProductSlice';

export default function Header() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    let user = useSelector(storeUser);
    const navigate = useNavigate();
    const [imagePath, setImagePath] = useState('/assets/images/profile_icon.png');

    const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';
    
    if(owner_data === '') {
        window.location.href = '/domain-not-found';
    }

    let logo_url = (owner_data && owner_data.image_url!="") ? owner_data.image_url: "/assets/images/recycling-network.png";
    let mobile_no = (owner_data) ? owner_data.mobile_no: "";
    let meta_title = (owner_data) ? owner_data.cmp_name: "Recycling Network";
    
    const handleLogout = () => {
        dispatch(Logout()).then((res) => {
            if (res.status === 200 || res.status === 401) {
                dispatch(resetAllData());
                setImagePath('');
                localStorage.removeItem('skipgireusertoken')
                localStorage.removeItem('skiphire_guest_user')
                dispatch(resetLocalStorageValues())
                navigate("/login");
            }
        });
    }
  return (
    <>
    <div className="application">
            <Helmet>
                <title>{meta_title}</title>
            </Helmet>
        </div>
        <nav className="navbar navbar-light bg-light static-top">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src={logo_url} style={{ width: '200px'}} alt="..." />
                </Link>

                <span href="#signup">{ t('Advice hotline') }<br/>
                    <a style={{ fontSize: '18px' }} className="" href={'tel:'+mobile_no}>{mobile_no}</a>
                </span>
                  {/* {owner_data && owner_data.owner_settings && owner_data.owner_settings.primary_website && <span><a href={owner_data.owner_settings.primary_website} target="_blank">{ t('Main Website') }</a></span>} */}
                <div className="dropdown text-end">
                    <a href="" className="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false">
                          <img src={(Object.keys(user).length > 0) ? user.profile_image : imagePath } alt="mdo" width="32" height="32" className="rounded-circle" />
                    </a>
                      <ul className="dropdown-menu text-small">
                        { (() => {
                            if (Object.keys(user).length > 0) {
                                return(
                                    <>
                                        <li><Link to="/" className="dropdown-item">{ t('Welcome') } : { user.first_name+' '+user.last_name }</Link></li>
                                        <li><Link to="/profile" className="dropdown-item">{ t('Profile') }</Link></li>
                                        {/* <li><Link to="/saved-address" className="dropdown-item">{ t('Address') }</Link></li> */}
                                        <li><Link to="/orders" className="dropdown-item">{ t('Orders') }</Link></li>
                                        <li><Link to="/order-documents" className="dropdown-item">{ t('Order Document') }</Link></li>
                                        <li><Link to="/change-password" className="dropdown-item">{ t('Change Password') }</Link></li>
                                        <li><Link onClick={handleLogout} className="dropdown-item">{ t('Logout') }</Link></li>
                                    </>
                                )
                            } else {
                                return(
                                    <>
                                        <li><Link to="/login" className="dropdown-item">{ t('Login') }</Link></li>
                                        <li><Link to="/signup" className="dropdown-item" >{ t('Register') }</Link></li>
                                    </>
                                )
                            }
                        }) ()}  
                    </ul>
                </div>
            </div>
        </nav>
        <nav className="py-2 bg-light border-bottom">
            <div className="container d-flex flex-wrap">
                <ul className="nav me-auto">
                </ul>
                <ul className="nav">
                      <li className="nav-item mx-4"><Link to="/" className="nav-link link-dark px-2">{ t('Home') }</Link></li>
                        
                    {owner_data && owner_data.can_manage_material_module==1 ? 
                      <li className="nav-item mx-4"><Link to="/blogs" className="nav-link link-dark px-2">{t('Offers')}</Link></li>
                      : <li className="nav-item mx-4"><Link to="/blogs-list" className="nav-link link-dark px-2">{t('Offers')}</Link></li>
                    }

                      <li className="nav-item mx-4"><Link to="/contact-us" className="nav-link link-dark px-2">{ t('Contact us') }</Link></li>
                      <li className="nav-item mx-4"><Link to="/about-us" className="nav-link link-dark px-2">{ t('About us') }</Link></li>
                      {owner_data && owner_data.owner_settings && owner_data.owner_settings.primary_website && <li className="nav-item mx-4"><a className="nav-link link-dark px-2" href={owner_data.owner_settings.primary_website} target="_blank">{ t('Main Website') }</a></li>}
                </ul>
            </div>
        </nav>
    </>
  )
}
