import React from 'react'
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';

export default function MaterialCategoryCard(props) {

    const navigate = useNavigate();
    
    const getContainerListCallback = (id) => {
        localStorage.setItem('material_category_id', id);
        localStorage.setItem('selected_material_category', JSON.stringify(props.category));
        navigate('/material-products/');
    }

    const handleClick = () => {
        $('.modal-body').html(props.category.description);
        $('.modal-title').html(props.category.title);
    }

  return (
    <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative" style={{backgroundColor: props.category.color}}>
        <div className="col-auto d-lg-block material-category-title"  onClick={() => getContainerListCallback(props.category.id)}>
            <img src={props.category.image_url} className="bd-placeholder-img rounded-start" width="160" height="225" alt={props.category.title} />
        </div>
        <div className="col d-flex flex-column">
            <div className="card-body">
            <svg onClick={handleClick}  data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="float-end" style={{cursor:'pointer'}} stroke="currentColor" width={35}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
                <h5 className="card-title material-category-title">{ (props.category.title.length > 25) ? props.category.title.substring(0,25)+'...' : props.category.title}
                </h5> 
                <p className="card-text" >
                    {(props.category.description.length > 185) ? props.category.description.substring(0, 185).replace(/[\r\n]/gm, '') + '...' : props.category.description.replace(/[\r\n]/gm, '')}
                </p>
            </div>
        </div>
    </div>
  )
}
