import React, { useEffect, useState } from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import LoadingSpinner from './../Components/LoadingSpinner'
import UserSidebarMenu from './../Components/UserSidebarMenu'
import { useSelector, useDispatch } from 'react-redux'
import { getSavedAddress, saveAddress, updateAddress, storeAddressList, storeSaveAddressErrors } from './../Store/Slices/UserSavedAddressSlice'
import { toast } from 'react-toastify';
import { t } from 'i18next'

export default function SavedAddress() {

    const dispatch = useDispatch()

    let addressList = useSelector(storeAddressList);
    let saveAddressErrors = useSelector(storeSaveAddressErrors);

    useEffect(() => {
        dispatch(getSavedAddress()).then(() => {});
    }, [dispatch]);
    
    const [selectedAddress, setSelectedAddress] = useState({
        address_id:0,
        id: 0,
        delivery_street: '',
        delivery_address_2: '',
        delivery_city: '',
        delivery_zip_code: '',
        delivery_country: '',
        remarks: '',
    });

    const [loading, setLoading] = useState(false);
    const handleUpdateAddressClick = () => {
        setLoading(true);
        if (selectedAddress.id === 0) { //Add new address
            dispatch(saveAddress(selectedAddress)).then((data) => {
                if (data.status === 200) {
                    toast.success(data.message)
                    setSelectedAddress({
                        id: 0,
                        delivery_street: '',
                        delivery_address_2: '',
                        delivery_city: '',
                        delivery_zip_code: '',
                        delivery_country: '',
                        remarks: '',
                    });
                }
                setLoading(false);
            });
        } else { // Update address
            dispatch(updateAddress(selectedAddress)).then((data) => {
                if (data.status === 200) {
                    toast.success(data.message)
                    setSelectedAddress({
                        id: 0,
                        delivery_street: '',
                        delivery_address_2: '',
                        delivery_city: '',
                        delivery_zip_code: '',
                        delivery_country: '',
                        remarks: '',
                    });
                } else {
                    // toast.error(data.message)
                }
                setLoading(false);
            });
        }
    }

    const handleInputChange = (e) => {
        setSelectedAddress({ ...selectedAddress, [e.target.id]: e.target.value });
    }

    const handleSelectAddressClick = (address) => {
        if (selectedAddress.id === address.id) {
            setSelectedAddress({
                id: 0,
                delivery_street: '',
                delivery_address_2: '',
                delivery_city: '',
                delivery_zip_code: '',
                delivery_country: '',
                remarks: '',
            });
        } else {
            setSelectedAddress({ ...address, address_id:address.id });   
        }
    }

    const getFullAddress = (address) => {
        let add = address.delivery_street;
        add += (address.delivery_address_2) ? ', '+address.delivery_address_2 : '';
        add += (address.delivery_city) ? ', '+address.delivery_city : '';
        add += (address.delivery_zip_code) ? ', ' + address.delivery_zip_code : '';
        return add;
    }

  return (
    <FrontendLayout>
            <div className="container rounded mt-2 mb-5">
              <div className="row mt-5">
                    <div className='col-md-3'>
                    <UserSidebarMenu />
                    </div>
                    <div className="col-md-4 border-right overflow-auto" style={{height:'400px'}}>
                        <ul className='nav'>
                          {addressList.map((row, index) => (<li key={index} className={`card shadow w-100 rounded nav-item pt-3 ps-2 border-0 mt-2 ${selectedAddress.id === row.id ? 'theme-light-green' : 'bg-white'}`} style={{cursor:'pointer'}} onClick={()=>handleSelectAddressClick(row)}>
                            <p>{getFullAddress(row)}</p>
                            </li>))}
                        </ul>
                    </div>
                    <div className="col-md-5 border-right">
                        <div className="p-1 card border-0 shadow bg-white rounded p-3">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="text-right"><i className="fas fa-map-marker-alt"></i> {(selectedAddress.id!==0) ? t('Update address') : t('Add new address') }</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="labels">{ t('Street') } *</label>
                                    <input type="text" className={`form-control h-50 ${saveAddressErrors.hasOwnProperty('delivery_street') && 'is-invalid'}`} id='delivery_street' onChange={handleInputChange} value={selectedAddress.delivery_street} />
                                    {saveAddressErrors.hasOwnProperty('delivery_street') && <div className="invalid-feedback">{saveAddressErrors.delivery_street}</div>}
                                </div>
                                <div className="col-md-6">
                                  <label className="labels">{ t('Street 2') }</label>
                                    <input type="text" className={`form-control h-50 ${saveAddressErrors.hasOwnProperty('delivery_address_2') && 'is-invalid'}`} id='delivery_address_2' onChange={handleInputChange} value={selectedAddress.delivery_address_2} />
                                    {saveAddressErrors.hasOwnProperty('delivery_address_2') && <div className="invalid-feedback">{saveAddressErrors.delivery_address_2}</div>}
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label className="labels">{ t('Postal code') } *</label>
                                    <input type="text" className={`form-control h-50 ${saveAddressErrors.hasOwnProperty('delivery_zip_code') && 'is-invalid'}`} id='delivery_zip_code' onChange={handleInputChange} value={selectedAddress.delivery_zip_code} />
                                    {saveAddressErrors.hasOwnProperty('delivery_zip_code') && <div className="invalid-feedback">{saveAddressErrors.delivery_zip_code}</div>}
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label className="labels">{ t('City') } *</label>
                                    <input type="text" className={`form-control h-50 ${saveAddressErrors.hasOwnProperty('delivery_city') && 'is-invalid'}`} id='delivery_city' onChange={handleInputChange} value={selectedAddress.delivery_city} />
                                    {saveAddressErrors.hasOwnProperty('delivery_city') && <div className="invalid-feedback">{saveAddressErrors.delivery_city}</div>}
                                </div>
                            </div>
                            
                          <div className="mt-2 text-left "><button disabled={loading} onClick={handleUpdateAddressClick} className="btn theme-btn btn-sm profile-button " type="button">{loading && <LoadingSpinner />}{ t('Save Address') }</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </FrontendLayout>
  )
}
