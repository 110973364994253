import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getSingleBlog, storeSingleBlog } from '../Store/Slices/BlogSlice'
import FrontendLayout from '../Layout/FrontendLayout';
import { useDispatch, useSelector } from 'react-redux';

export default function SingleBlog() {
    const { id } = useParams();
    const dispatch = useDispatch();
    let singleBlog = useSelector(storeSingleBlog);
    
    useEffect(() => {
        dispatch(getSingleBlog(id));
    }, [dispatch]);
    
    return (
        <FrontendLayout>
            <section className="container">
                <div className="row mt-5">
                    <div className="col-12 theme-light-yellow p-4 rounded theme-font-blue">
                        <h2><Link to='/blogs'><img height={25} className="me-2" src="/assets/images/images/roundArrowLeft.png" alt='arrow-left' /></Link>{singleBlog.title}</h2>
                    </div>
                    <div className="row mt-3">
                        <img src={singleBlog.image} alt={singleBlog.title} className="img mx-auto" />
                        <p className='mt-3' dangerouslySetInnerHTML={{__html:singleBlog.description}}></p>
                    </div>
                </div>
            </section>
        </FrontendLayout>
  )
}
