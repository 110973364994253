import React from 'react'
import ReactLoading from 'react-loading';


export default function FullScreenLoading() {

  const styleMain = {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100px',
    height: '100px',
  }
  return (
    <>
      <div style={styleMain}>
        <ReactLoading type='spin' color='#000' />
      </div>
    </>
  )
}
