import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import {config, CheckAuth, getApiToken} from './../../constans';

export const OrderSlice = createSlice({
    name: 'OrderSlice',
    initialState: {
          orderList: [],
          orderDocumentsList: [],
          orderErrors: '',
          orderErrorMessage: ''
    },
    reducers: {
        setOrderList: (state, { payload }) => {
            state.orderList = payload.data;
        },
        setOrderDocumentsList: (state, { payload }) => {
            state.orderDocumentsList = payload.data;
        },
        setOrderErrors: (state, {payload}) => {
            state.orderErrors = payload.errors;
            state.orderErrorMessage = payload.message;
        },
        resetOrderErrors: (state) => {
            state.orderErrors = {};
            state.orderErrorMessage = '';
        }
    },
})
  
// Action creators are generated for each case reducer function
export const { setOrderList, setOrderDocumentsList, setOrderErrors, resetOrderErrors } = OrderSlice.actions
  
// Selectors
export const storeOrderList = state => state.OrderSlice.orderList;
export const storeOrderDocumentsList = state => state.OrderSlice.orderDocumentsList;
export const storeOrderErrors = state => state.OrderSlice.orderErrors;
export const storeOrderErrorMessage = state => state.OrderSlice.orderErrorMessage;
  
export default OrderSlice.reducer
  
  // API Function
export function getOrders() {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_GET_ORDER_LIST, {}, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setOrderList(data))
                } else {
                    CheckAuth(data.status);
                    dispatch(setOrderErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setOrderErrors(error.response))
            });
    }
}

export function getOrderDocuments() {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_GET_ORDER_NOTES_DOCUMENTS, {}, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setOrderDocumentsList(data))
                } else {
                    dispatch(setOrderErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setOrderErrors(error.response))
            });
    }
}

export function makeOrderPickupRequest(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_MAKE_PICKUP_REQUEST, params, options)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
            });
    }
}