import React, { useEffect } from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import { config } from '../constans';

export default function TermsofService() {

    let owner_id = localStorage.getItem('MAIN_OWNER_ID');

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])

  return (
    <FrontendLayout>
        <section className="container">
            <div className="row mt-3" style={{height:'700px'}}>
                    <iframe 
                      id="pagesIframe"
                      name="TermsofService"
                      src={config.APP_URL+'page/'+owner_id+'/terms-of-service'}
                      frameBorder="0"
                    ></iframe>
            </div>
        </section>
    </FrontendLayout>
  )
}
