import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {

    const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';

    let android_app_url = (owner_data && owner_data.android_app_url && owner_data.android_app_url!='null') ? owner_data.android_app_url : "#";
    let ios_app_url = (owner_data && owner_data.ios_app_url && owner_data.ios_app_url!='null') ? owner_data.ios_app_url: "#";
    let logo_url = (owner_data && owner_data.image_url) ? owner_data.image_url: "/assets/images/recycling-network.png";
    let qr_code_image = (owner_data && owner_data.qr_code_image && owner_data.qr_code_image!='null') ? owner_data.qr_code_image : "";

    const footerBgColor = (owner_data && owner_data.owner_settings && owner_data.owner_settings.footer_bg_color) ? owner_data.owner_settings.footer_bg_color : "#373F43";
    const footerTextColor = (owner_data && owner_data.owner_settings && owner_data.owner_settings.footer_text_color) ? owner_data.owner_settings.footer_text_color : "#FFFFFF";
    const footerSecondTextColor = (owner_data && owner_data.owner_settings && owner_data.owner_settings.footer_second_text_color) ? owner_data.owner_settings.footer_second_text_color : "#898989";

    const styles = {
        "backgroundColor": "white",
        "padding": "10px",
        "width": "160px"  
    }

  return (
    <footer className="footer" style={{backgroundColor:footerBgColor, color: footerTextColor}}>
        <div className="container p-4" style={{backgroundColor:footerBgColor, color: footerTextColor}}>
            <div className="row">
                <div className="col-md-3 col-lg-3 col-sm-6 col-sx-12 mb-3">
                {(() => {
                    if(ios_app_url!='#' || android_app_url!='#'){
                        return (
                            <>
                            <h3 style={{color:footerTextColor}}>{ t('Download our app') }</h3>
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-sm-4 col-sx-12">
                                    {qr_code_image &&  <img src={qr_code_image} className="w-full" style={styles} alt="qr code" />}
                                </div>
                            </div>
                            </>
                        )
                    }
                    
                    return null;
                })()}
                </div>
                
                <div className="col-md-3 col-lg-3 col-sm-6 col-sx-12 mb-3">
                        {/* <h3 className="text-white"></h3> */}
                       
                        { ios_app_url!='#' &&
                                <a href={ios_app_url} target="_blank" rel="noreferrer"><img src="/assets/images/appstore.png" width="165" className="mt-2" alt="ios app" /></a> }

                        { android_app_url!='#' &&
                                <a href={android_app_url} target="_blank" rel="noreferrer"><img src="/assets/images/playstore.png" width="165" className="mt-2" alt="android app" /></a> }
                </div>

                <div className="col-md-3 col-lg-3 col-sm-6 col-sx-12 mb-3">
                    <h3 style={{color:footerTextColor}}>{ t('About us') }</h3>
                      <Link to="/imprint" style={{ color: footerSecondTextColor, fontSize: '17px' }}>{ t('Imprint') }</Link><br />
                      <Link to="/terms-of-service" style={{ color: footerSecondTextColor, fontSize: '17px' }}>{ t('Terms of service') }</Link><br />
                      <Link to="/privacy-policy" style={{ color: footerSecondTextColor, fontSize: '17px' }}>{ t('Privacy Policy') }</Link><br />
                      <Link to='/right-of-withdrawal' style={{ color: footerSecondTextColor, fontSize: '17px' }}>{ t('Right of withdrawal') }</Link><br />
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6 col-sx-12 mb-3">
                    <h3 style={{color:footerTextColor}}>{ t('Contact') }</h3>
                    <Link to="/" style={{color: footerSecondTextColor, fontSize: '17px'}}>
                        <img src={logo_url} width="150" alt="logo" />
                    </Link><br/>
                    <p style={{color: footerSecondTextColor, fontSize: '17px'}}>{(owner_data) ? owner_data.address_1: ""} <br/>{(owner_data) ? owner_data.address_2: ""}</p>
                    <p style={{color: footerSecondTextColor, fontSize: '18px'}}>{ t('Telephone') }: {(owner_data) ? owner_data.mobile_no: ""}</p>
                    <p style={{color: footerSecondTextColor, fontSize: '17px'}}>{ t('EMail') }: <br/>{(owner_data) ? owner_data.email : ""}</p>
                </div>
            </div>
        </div>
    </footer>
  )
}
