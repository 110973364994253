import { t } from 'i18next'
import React from 'react'

export default function SkipHireSteps() {

    const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';
    
  return (
    <section className="features-icons text-center">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 ">
                    <h2 className="float-start">{ t("Order containers online: It's that easy!") }</h2>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 pb-3">
                    <div className="instruction-card rounded h-100 features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3" style={{backgroundColor: (owner_data && owner_data.owner_settings && owner_data.owner_settings.hp_card_bg_color) ? owner_data.owner_settings.hp_card_bg_color : '#f0c530bf'}}>
                        <h3 className="mt-4" style={{color: (owner_data && owner_data.owner_settings && owner_data.owner_settings.hp_card_text_color) ? owner_data.owner_settings.hp_card_text_color : '#5495A6', fontSize: '35px'}}><strong className="text-white"
                              style={{ fontSize: '50px' }}>1</strong>
                              <br />
                              {t('Enter zip code')}</h3>
                        <div className="features-icons-icon mx-auto mt-4">
                              <img src="assets/images/house2.png" className='img-fluid' width={180} alt={ t('Enter zip code') } />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 pb-3 ">
                    <div className="instruction-card rounded h-100 features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3" style={{backgroundColor: (owner_data && owner_data.owner_settings && owner_data.owner_settings.hp_card_bg_color) ? owner_data.owner_settings.hp_card_bg_color : '#f0c530bf'}}>
                        <h3 className="mt-4" style={{color: (owner_data && owner_data.owner_settings && owner_data.owner_settings.hp_card_text_color) ? owner_data.owner_settings.hp_card_text_color : '#5495A6', fontSize: '35px'}}><strong className="text-white"
                              style={{ fontSize: '50px' }}>2</strong>
                              <br />
                              {t('Select waste type')}</h3>
                        <div className="features-icons-icon mx-auto mt-5">
                              <img src="assets/images/cont_pics.png" className='img-fluid' alt={t('Select waste type')} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 pb-3">
                    <div className="instruction-card rounded h-100 features-icons-item px-5 mx-auto mb-5 mb-lg-0 mb-lg-3" style={{backgroundColor: (owner_data && owner_data.owner_settings && owner_data.owner_settings.hp_card_bg_color) ? owner_data.owner_settings.hp_card_bg_color : '#f0c530bf'}}>
                            <h3 className="mt-4" style={{color: (owner_data && owner_data.owner_settings && owner_data.owner_settings.hp_card_text_color) ? owner_data.owner_settings.hp_card_text_color : '#5495A6', fontSize: '30px'}}><strong className="text-white"
                              style={{ fontSize: '50px' }}>3</strong>
                              <br />
                              {t('Determine container size')}</h3>
                            <div className="features-icons-icon mx-auto">
                              <img src="assets/images/cont_pics_size.png" className='img-fluid mt-5' width={300} alt={t('Determine container size')}  />
                            </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 pb-3">
                    <div className="instruction-card rounded h-100 features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3" style={{backgroundColor: (owner_data && owner_data.owner_settings && owner_data.owner_settings.hp_card_bg_color) ? owner_data.owner_settings.hp_card_bg_color : '#f0c530bf'}}>
                        <h3 className="mt-4" style={{color: (owner_data && owner_data.owner_settings && owner_data.owner_settings.hp_card_text_color) ? owner_data.owner_settings.hp_card_text_color : '#5495A6', fontSize: '35px'}}>
                              <strong className="text-white" style={{ fontSize: '50px' }}>4</strong>
                              <br />
                              {t('Have it delivered on the desired date')}</h3>
                        <div className="features-icons-icon mx-auto mt-5"><img src="assets/images/clock2.png" className='img-fluid' alt={t('Have it delivered on the desired date')} width={120}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
