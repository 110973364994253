import React, { useState } from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import LoadingSpinner from './../Components/LoadingSpinner'
import { useSelector, useDispatch } from 'react-redux'
import { UpdateProfile, storeUser } from './../Store/Slices/UserAuthSlice'
import { toast } from 'react-toastify';
import UserSidebarMenu from './../Components/UserSidebarMenu';
import { t } from 'i18next';
import $ from 'jquery';
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function Profile() {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    let user = useSelector(storeUser);
    const [updateProfileForm, setupdateProfileForm] = useState(user);
    const [profilePicture, setprofilePicture] = useState()
    const [profileFormError, setprofileFormError] = useState()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(localStorage.getItem('skipgireusertoken')=="" || localStorage.getItem('skipgireusertoken')==null){
            navigate('/login')
        }
    }, [])
    
    const handleUpdateProfileClick = (event) => {
        event.preventDefault()
        setLoading(true);
        const formData = new FormData();
        if(updateProfileForm.first_name){
            formData.append('name', updateProfileForm.first_name);
        }
        if(updateProfileForm.last_name){
            formData.append('last_name', updateProfileForm.last_name);
        }
        if(updateProfileForm.mobile_no){
            formData.append('mobile_no', updateProfileForm.mobile_no);
        }
        if(updateProfileForm.billing_street){
            formData.append('street', updateProfileForm.billing_street);
        }
        if(updateProfileForm.billing_address_2){
            formData.append('address_2', updateProfileForm.billing_address_2);
        }
        if(updateProfileForm.billing_city){
            formData.append('city', updateProfileForm.billing_city);
        }
        if(updateProfileForm.billing_zip_code){
            formData.append('zip_code', updateProfileForm.billing_zip_code);
        }
        if(updateProfileForm.billing_country){
            formData.append('country', updateProfileForm.billing_country);
        }
        if(updateProfileForm.delivery_street){
            formData.append('delivery_street', updateProfileForm.delivery_street);
        }
        if(updateProfileForm.delivery_address_2){
            formData.append('delivery_address_2', updateProfileForm.delivery_address_2);
        }
        if(updateProfileForm.delivery_city){
            formData.append('delivery_city', updateProfileForm.delivery_city);
        }
        if(updateProfileForm.delivery_zip_code){
            formData.append('delivery_zip_code', updateProfileForm.delivery_zip_code);
        }
        if(updateProfileForm.delivery_country){
            formData.append('delivery_country', updateProfileForm.delivery_country);
        }
        if(updateProfileForm.delivery_address_id){
            formData.append('address_id', updateProfileForm.delivery_address_id);
        }
        if(updateProfileForm.company_name){
            formData.append('company_name', updateProfileForm.company_name);
        }
        
        if (profilePicture) {
            formData.append('user_image', profilePicture);
        }

        dispatch(UpdateProfile(formData)).then((data) => {
            if (data.status === 200) {
                setprofileFormError('');
                setupdateProfileForm(data.data);
                toast.success(data.message)
                $('input[type="file"]').val('');
            }

            if(data.status == 0 && data.hasOwnProperty('errors')){
                setprofileFormError(data.errors);
            }
            setLoading(false);
        });
    }

    function handleProfilePictureChange(event) {
        setprofilePicture(event.target.files[0])
    }
    const handleInputChange = (e) => {
        setupdateProfileForm({ ...updateProfileForm, [e.target.id]: e.target.value });
    }
    
    return (
        <FrontendLayout>
            <div className="container rounded mt-2 mb-5">
                <div className="row mt-5">
                    <div className='col-md-3 order-md-1 mt-5'>
                    <UserSidebarMenu />
                    </div>
                    <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 mt-5 order-md-2">
                    <form onSubmit={handleUpdateProfileClick}>
                        <div className="p-3">
                            <div className="d-flex flex-column text-center" style={{background:'transparent'}}>
                                <div className="card border-0" style={{maxWidth: '540px'}}>
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <img src={(updateProfileForm.hasOwnProperty('profile_image')) ? updateProfileForm.profile_image : "https://via.placeholder.com/150?text=Skip"} alt='profile-user' className="img-fluid rounded-circle" width={150} height={150} />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">{updateProfileForm.first_name + ' ' + updateProfileForm.last_name}</h5>
                                                <p className="card-text">{updateProfileForm.email}</p>
                                                <p className="card-text"><span><input type="file" onChange={handleProfilePictureChange} /></span></p>
                                                {(profileFormError && profileFormError.hasOwnProperty('user_image')) ? <div className="invalid-feedback">{profileFormError.user_image}</div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-3 justify-content-between align-items-center mb-1">
                                <h5 className="text-right">{ t('Profile Settings') }</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="labels">{ t('First Name') }</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('name')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} id='first_name' onChange={handleInputChange} value={updateProfileForm.first_name} />
                                    {(profileFormError && profileFormError.hasOwnProperty('name')) ? <div className="invalid-feedback">{profileFormError.name}</div> : ''}
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('Last Name') }</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('last_name')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} onChange={handleInputChange} value={updateProfileForm.last_name} id="last_name" />
                                    {(profileFormError && profileFormError.hasOwnProperty('last_name')) ? <div className="invalid-feedback">{profileFormError.last_name}</div> : ''}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="mobile_no">{ t('phone_number') } *</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('mobile_no')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} onChange={handleInputChange} required="required" value={updateProfileForm.mobile_no} id="mobile_no" />
                                    {(profileFormError && profileFormError.hasOwnProperty('mobile_no')) ? <div className="invalid-feedback">{profileFormError.mobile_no}</div> : ''}
                                </div>

                                <div className="col-md-6">
                                    <label className="labels">{ t('Company name') }</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('company_name')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} onChange={handleInputChange} value={updateProfileForm.company_name} id="company_name" />
                                    {(profileFormError && profileFormError.hasOwnProperty('company_name')) ? <div className="invalid-feedback">{profileFormError.company_name}</div> : ''}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 mb-1">
                                <h5 className="text-right">{ t('Billing Address') }</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="labels">{ t('Street') }</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('street')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} onChange={handleInputChange} value={updateProfileForm.billing_street} id='billing_street' />
                                    {(profileFormError && profileFormError.hasOwnProperty('street')) ? <div className="invalid-feedback">{profileFormError.street}</div> : ''}
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('Street 2') }</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('address_2')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} id='billing_address_2' onChange={handleInputChange} value={updateProfileForm.billing_address_2} />
                                    {(profileFormError && profileFormError.hasOwnProperty('address_2')) ? <div className="invalid-feedback">{profileFormError.address_2}</div> : ''}
                                    
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('Postal code') }</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('zip_code')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} id='billing_zip_code' onChange={handleInputChange} value={updateProfileForm.billing_zip_code} />
                                    {(profileFormError && profileFormError.hasOwnProperty('zip_code')) ? <div className="invalid-feedback">{profileFormError.zip_code}</div> : ''}
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('City') }</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('city')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} id='billing_city' onChange={handleInputChange} value={updateProfileForm.billing_city} />
                                    {(profileFormError && profileFormError.hasOwnProperty('city')) ? <div className="invalid-feedback">{profileFormError.city}</div> : ''}
                                </div>
                                {updateProfileForm.delivery_address < 1 && <div className="col-md-6">
                                    <label className="labels">{ t('Country') }</label>
                                    <input type="text" className={(profileFormError && profileFormError.hasOwnProperty('country')) ? 'form-control h-50 is-invalid' : 'form-control h-50'} id='billing_country' onChange={handleInputChange} value={updateProfileForm.billing_country} />
                                    {(profileFormError && profileFormError.hasOwnProperty('country')) ? <div className="invalid-feedback">{profileFormError.country}</div> : ''}
                                </div>}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 mb-1">
                                <h5 className="text-right">{ t('Delivery Address') }</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="labels">{ t('Street') }</label>
                                    <input type="text" className="form-control h-50" id='delivery_street' onChange={handleInputChange} value={updateProfileForm.delivery_street} />
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('Street 2') }</label>
                                    <input type="text" className="form-control h-50" id='delivery_address_2' onChange={handleInputChange} value={updateProfileForm.delivery_address_2} />
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('Postal code') }</label>
                                    <input type="text" className="form-control h-50" id='delivery_zip_code' onChange={handleInputChange} value={updateProfileForm.delivery_zip_code} />
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">{ t('City') }</label>
                                    <input type="text" className="form-control h-50" id='delivery_city' onChange={handleInputChange} value={updateProfileForm.delivery_city} />
                                </div>
                                {updateProfileForm.delivery_address < 1 && <div className="col-md-6">
                                    <label className="labels">{ t('Country') }</label>
                                    <input type="text" className="form-control" id='delivery_country' onChange={handleInputChange} value={updateProfileForm.delivery_country} />
                                </div>}
                            </div>
                            <div className="mt-1 text-left"><button disabled={loading} className="btn theme-btn profile-button btn-sm" type="submit">{loading && <LoadingSpinner />}{ t('Save Profile') }</button></div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </FrontendLayout>
    )
}
