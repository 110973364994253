import React from 'react'
import { t } from 'i18next'

export default function ContainerTypeCard(props) {
  return (
    <div className={ (props.selectedContainer===props.container.id) ? "card mb-3 theme-light-yellow border border-secondary border-2" :  "card mb-3 theme-light-yellow" } id="list-container">
        <div className="row g-0">
            <div className="col-md-4 col-lg-4 col-sm-12 col-sx-12">
                  <img style={{marginTop:'35%'}} src={ (props.container.container_image) ? props.container.container_image : '/assets/images/images/gips.jpeg' } className="w-100 h-auto" />
            </div>
            <div className="col-md-8 col-lg-8 col-sn-12 col-sx-12">
                <div className="card-body">
            <h5 className="card-title">{props.container.size}m<sup>3</sup> {props.container.name}</h5>
                      <p className="card-text" dangerouslySetInnerHTML={{ __html: props.container.description }}></p>
                      <h3>{props.container.final_price} €</h3>
                      <h5>({props.container.price} €/{t('Net')})</h5>
                      <p className="card-text"><small className="text-muted">{props.container.final_price} € / {props.container.size}m<sup>3</sup> = {(parseInt(props.container.final_price)/parseInt(props.container.size)).toLocaleString()} € pro m<sup>3</sup></small>
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
