import React from 'react'
import moment from 'moment';

export default function BlogCard(props) {
    moment.locale('de');
  return (
    <div className="card mb-4">
        <img className="card-img-top" src={props.blog.image} alt={props.blog.title} />
        <div className="card-body">
            <h5 className="card-title">{props.blog.title}</h5>
            <p className="card-text" dangerouslySetInnerHTML={{__html:props.blog.description.substring(0,150)}}></p>
        </div>
    </div>
  )
}
