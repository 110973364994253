import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import axios from 'axios';
import { config } from './constans';

// Pages
import Home from './Pages/Home';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import ForgotPassword from './Pages/ForgotPassword';

// Pages with Auth
import Profile from './Pages/Profile';
import SavedAddress from './Pages/SavedAddress';
import ChangePassword from './Pages/ChangePassword';
import Orders from './Pages/Orders';
import OrderDocuments from './Pages/OrderDocuments';
import SelectGarbageType from './Pages/SelectGarbageType';
import SelectContainer from './Pages/SelectContainer';
import SelectPaymentMethod from './Pages/SelectPaymentMethod';
import SelectAddress from './Pages/SelectAddress';
import ContactUs from './Pages/ContactUs';
import AllBlogs from './Pages/AllBlogs';
import SingleBlog from './Pages/SingleBlog';

import RightOfWithdrawal from './Pages/RightOfWithdrawal';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Imprint from './Pages/Imprint';
import TermsofService from './Pages/TermsofService';
import DeleteAccount from './Pages/DeleteAccount';

import FullScreenLoading from './Pages/FullScreenLoading';

import { Provider } from 'react-redux'
import store from './Store/store'
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AboutUs from './Pages/AboutUs';
import ThankYou from './Pages/ThankYou';
import DomainNotFound from './Pages/DomainNotFound';
import Logout from './Pages/Logout';
import BlogList from './Pages/BlogList';
import MaterialZipcode from './Pages/Material/MaterialZipcode';
import MaterialCategory from './Pages/Material/MaterialCategory';
import MaterialProduct from './Pages/Material/MaterialProduct';
import MateriaContainer from './Pages/Material/MaterialContainer';
import SelectMaterialPaymentMethod from './Pages/SelectMaterialPaymentMethod';
import MaterialOrderHistory from './Pages/Material/MaterialOrderHistory';
import MaterialOrderNotesAndInvoice from './Pages/Material/MaterialOrderNotesAndInvoice';

// Get Owner details by domain
var url = window.location.host;
var domain = url.replace('www.', '');

axios.post(config.API_BASE_URL + 'user/owner-details-by-domain', { domain: domain })
  .then((data) => {
    if (data.status === 200) {
      localStorage.setItem('owner_data', JSON.stringify(data.data.data));
      localStorage.setItem('MAIN_OWNER_ID', data.data.data.id);
      localStorage.setItem('vat_tax', data.data.data.vat_tax);
    } else {
      // window.location.href = '/domain-not-found';
      // return false;
      // alert(data.data.status);
    }
  }).catch((err) => {
  });

let getOwnerIdPromise = new Promise(function (myResolve, myReject) {
  axios.post(config.API_BASE_URL + 'user/owner-details-by-domain', { domain: domain })
    .then((data) => {
      if (data.status === 200) {
        localStorage.setItem('owner_data', JSON.stringify(data.data.data));
        localStorage.setItem('MAIN_OWNER_ID', data.data.data.id);
        localStorage.setItem('vat_tax', data.data.data.vat_tax);
      }
      myResolve(data);
    }).catch((err) => {
      myReject(err);
    });
});

// declare a request interceptor
axios.interceptors.request.use(request_config => {
  // perform a task before the request is sent
  if (request_config.url.substring(0, 22) !== 'https://api.stripe.com') {
    if (!request_config.hasOwnProperty('data') || request_config.data.owner_id === null) {
      getOwnerIdPromise.then(
        function (value) {
          request_config.data = {"owner_id":value.data.data.id};
        },
        function (error) {
        }
      );
    }
  }

  return request_config;
}, error => {
  // handle the error
  return Promise.reject(error);
});


let root;

root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FullScreenLoading />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

getOwnerIdPromise.then(
  function (value) {

    root.render(
      // <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup-as-guest" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
    
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blogs" element={<AllBlogs />} />
            <Route path="/blogs/:id" element={<SingleBlog />} />
            <Route path="/right-of-withdrawal" element={<RightOfWithdrawal />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/terms-of-service" element={<TermsofService />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/blogs-list" element={<BlogList />} />
    
            <Route path="/profile" element={<Profile />} />
            <Route path="/saved-address" element={<SavedAddress />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/order-documents" element={<OrderDocuments />} />
            <Route path="/select-garbage-type" element={<SelectGarbageType />} />
            <Route path="/select-container/:id" element={<SelectContainer />} />
            <Route path="/select-payment-method/:id" element={<SelectPaymentMethod />} />
            <Route path="/select-address/:id" element={<SelectAddress />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/domain-not-found" element={<DomainNotFound />} />

            {/* Material module */}
            <Route path="/material-zipcode" element={<MaterialZipcode />} />
            <Route path="/material-category-list" element={<MaterialCategory />} />
            <Route path="/material-products" element={<MaterialProduct />} />
            <Route path="/material-products-containers" element={<MateriaContainer />} />
            <Route path="/select-material-payment-method" element={<SelectMaterialPaymentMethod />} />
            <Route path="/material-order-history" element={<MaterialOrderHistory />} />
            <Route path="/material-order-documents" element={<MaterialOrderNotesAndInvoice />} />

            <Route path="/logout" element={<Logout />} />
          </Routes>
        </BrowserRouter>
      </Provider>
      // </React.StrictMode>
    );
  },
  function (error) {
  }
);

reportWebVitals();
