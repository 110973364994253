import { configureStore } from '@reduxjs/toolkit'
import UserAuthSlice from './Slices/UserAuthSlice'
import UserRegisterSlice from './Slices/UserRegisterSlice'
import UserSavedAddressSlice from './Slices/UserSavedAddressSlice'
import OrderSlice from './Slices/OrderSlice'
import GarbageSlice from './Slices/GarbageSlice'
import PaymentSlice from './Slices/PaymentSlice'
import ContactUsSlice from './Slices/ContactUsSlice'
import BlogSlice from './Slices/BlogSlice'
import CouponCodeSlice from './Slices/CouponCodeSlice'
import MaterialCategorySlice from './Slices/MaterialCategorySlice'
import MaterialProductSlice from './Slices/MaterialProductSlice'

export default configureStore({
    reducer: {
        UserAuthSlice: UserAuthSlice,
        UserRegisterSlice: UserRegisterSlice,
        UserSavedAddressSlice: UserSavedAddressSlice,
        OrderSlice: OrderSlice,
        GarbageSlice: GarbageSlice,
        PaymentSlice: PaymentSlice,
        ContactUsSlice: ContactUsSlice,
        CouponCodeSlice: CouponCodeSlice,
        BlogSlice: BlogSlice,
        MaterialCategorySlice: MaterialCategorySlice,
        MaterialProductSlice: MaterialProductSlice
    },
})