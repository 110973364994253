import React, { useEffect, useState } from 'react'
import { checkPostcode, storeZipCodeData } from './../Store/Slices/GarbageSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { config } from '../constans';
import { t } from 'i18next';
import { resetLocalStorageValues } from '../Store/Slices/MaterialProductSlice';

export default function HeaderBanner() {
    const dispatch = useDispatch();
    const [zipcode, setZipcode] = useState("");
    const navigate = useNavigate();
    const handleZipcodeChange = (e) => {
        setZipcode(e.target.value);
    }

    const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';
    
    let company_message = (owner_data) ? owner_data.company_message: "";
    const searchBtnBgColor = (owner_data && owner_data.owner_settings && owner_data.owner_settings.search_postcode_btn_color) ? owner_data.owner_settings.search_postcode_btn_color : "#0d6efd";
    const searchBtnTextColor = (owner_data && owner_data.owner_settings && owner_data.owner_settings.search_postcode_btn_txt_color) ? owner_data.owner_settings.search_postcode_btn_txt_color : "#ffffff";
    
    const handleCheckZipcodeClick = () => {
        dispatch(checkPostcode({ owner_id: localStorage.getItem('MAIN_OWNER_ID'), postcode: zipcode })).then((data) => {
            if (data.status === 0) {
                toast.error(data.message)
            } else {
                dispatch(resetLocalStorageValues())
                localStorage.setItem('selectedZIPCODE', JSON.stringify(data.data));
                localStorage.setItem('postcode_coupon_data', JSON.stringify(data.coupon_data));
                localStorage.setItem('coupon_applied', 0);
                navigate('/select-garbage-type')
            }
        });
    }

    const staticBackGroundImage = '/static/media/mainimage.05133dc125a1034280eb.png';

    return (
    <header className="masthead" style={{ backgroundImage: `url(${(owner_data && owner_data.owner_settings && owner_data.owner_settings.banner_image_url) ? owner_data.owner_settings.banner_image_url : staticBackGroundImage})`}}>
        <div className="container position-relative">
            <div className="row justify-content-center">
                <div className="col-xl-6">
                        <div className="text-center text-white">
                        <h1 className="mb-5" style={{ color:'#FFFFFF' }}>{(company_message == "null") ? "" : company_message}</h1>
                            <div className="form-group col-md-12">
                                <input type="text" className="form-control" onChange={handleZipcodeChange} placeholder={ t('Postal code') } value={zipcode} />
                            </div>
                            <div className="form-group col-md-12">
                              <button style={{ backgroundColor:searchBtnBgColor, border:0, color:searchBtnTextColor }} className="btn btn-primary mt-2" onClick={handleCheckZipcodeClick}>{ t('Search') }</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}
