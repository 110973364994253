import React, { useEffect, useState } from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Register, storeRegisterErrors } from './../Store/Slices/UserRegisterSlice'
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import LoadingSpinner from '../Components/LoadingSpinner';

export default function Signup() {
    let registrationErrors = useSelector(storeRegisterErrors);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    // let user_data = JSON.parse(localStorage.getItem('skipgireusertoken'));

    const [loading, setLoading] = useState(false);
    // Form 
    const [registerForm, setRegisterForm] = useState({
        name: '',
        last_name: '',
        email: '',
        mobile_no: '',
        password: '',
        company_name: '',
        street: '',
        address_2: '',
        city: '',
        zip_code: '',
        delivery_street: '',
        delivery_address_2: '',
        delivery_city: '',
        delivery_zip_code: '',
        user_type: (window.location.pathname === '/signup') ? 'login' : 'guest',
        owner_id: localStorage.getItem('MAIN_OWNER_ID')
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleInputChange = (e) => {
        setRegisterForm({ ...registerForm, [e.target.id]: e.target.value });
    }

    // Functions
    const handleRegisterClick = async (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(Register(registerForm)).then((data) => {
            setLoading(false);
            if (data.status === 200) {
                if (data.data.user_type === 'guest') {
                    localStorage.setItem('skiphire_guest_user', JSON.stringify(data.data));
                    let garbage_type = localStorage.getItem('garbage_id');
                    if(localStorage.getItem('appoinmentForm')!=null && localStorage.getItem('appoinmentForm')!="" && localStorage.getItem('appoinmentForm')!="undefined"){
                        const isreorder = localStorage.getItem('is_reorder');
                        if (isreorder === '1') {
                            navigate('/select-payment-method/' + garbage_type);
                        } else {
                            navigate('/select-address/' + garbage_type);
                        }
                    }else{
                        navigate('/select-container/' + garbage_type);
                    }
                } else {
                    navigate("/login");   
                }
            }
        });
    }

    const onChangeCheck = (event) => {
        if (event.target.checked) {
        
          setRegisterForm((registerForm) => {
            return { ...registerForm, delivery_street: registerForm.street };
          });

          setRegisterForm((registerForm) => {
            return { ...registerForm, delivery_address_2: registerForm.address_2 };
          });

          setRegisterForm((registerForm) => {
            return { ...registerForm, delivery_city: registerForm.city };
          });

          setRegisterForm((registerForm) => {
            return { ...registerForm, delivery_zip_code: registerForm.zip_code };
          });
        
        } else {
            setRegisterForm((registerForm) => {
                return { ...registerForm, delivery_street: '' };
            });
    
            setRegisterForm((registerForm) => {
                return { ...registerForm, delivery_address_2: '' };
            });
    
            setRegisterForm((registerForm) => {
                return { ...registerForm, delivery_city: '' };
            });
    
            setRegisterForm((registerForm) => {
                return { ...registerForm, delivery_zip_code: '' };
            });
        
        }
    };

  return (
    <FrontendLayout>
        <div className='mx-auto'>
        <section className="mt-5" >
            <div className="container py-2 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col col-xl-12">
                    <div className="card bg-light" style={{borderRadius: '1rem'}}>
                    <div className="row g-0">
                        <div className="col-md-12 col-lg-12 d-flex align-items-center">
                        <div className="card-body p-4 p-lg-5 text-black">
                            <form onSubmit={handleRegisterClick}>
                                { (() => {
                                    if(location.pathname === "/signup") {
                                        return (
                                        <h2 className="fw-normal mb-2 pb-3" style={{ letterSpacing: '1px' }}>
                                            { t('Create new account') }
                                            </h2>
                                        )
                                    } else {
                                        return(
                                            <h2 className="fw-normal mb-2 pb-3" style={{ letterSpacing: '1px' }}>
                                            { t('My Data') }
                                            </h2>
                                        )
                                    }
                                }) ()}
                                
                                <div className='row'>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="name">{ t('First Name') } *</label>
                                        <input type="text" id="name" onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('name')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('name')) ? <div className="invalid-feedback">{registrationErrors.name}</div> : ''}
                                    </div>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="last_name">{ t('Last Name') } *</label>
                                        <input type="text" id="last_name" onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('last_name')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('last_name')) ? <div className="invalid-feedback">{registrationErrors.last_name}</div> : ''}
                                    </div>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="email">{ t('EMail') } *</label>
                                        <input type="email" id="email" onChange={handleInputChange} className={`form-control ${(registrationErrors.hasOwnProperty('email')) ? 'is-invalid' : ''}`} required="required" />
                                        {(registrationErrors.hasOwnProperty('email')) ? <div className="invalid-feedback">{registrationErrors.email}</div> : ''}
                                    </div>

                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="mobile_no">{ t('phone_number') } *</label>
                                        <input type="text" id="mobile_no" onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('mobile_no')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('mobile_no')) ? <div className="invalid-feedback">{registrationErrors.mobile_no}</div> : ''}
                                    </div>

                                    { (() => {
                                        if(location.pathname === "/signup") {
                                            return (
                                            <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                                <label className="form-label" htmlFor="password">{ t('Password') } *</label>
                                                <input type="password" id="password" onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('password')) ? 'is-invalid' : ''}`} />
                                                {(registrationErrors.hasOwnProperty('password')) ? <div className="invalid-feedback">{registrationErrors.password}</div> : ''}
                                            </div>
                                            )
                                        }
                                    }) ()}
                                    
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="company_name">{ t('Company name') }</label>
                                        <input type="text" id="company_name" onChange={handleInputChange} className={`form-control ${(registrationErrors.hasOwnProperty('company_name')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('company_name')) ? <div className="invalid-feedback">{registrationErrors.company_name}</div> : ''}
                                    </div>
                                    
                                    <div className="form-outline mb-2 col-lg-12 col-md-12 col-sm-12 col-sx-12">
                                        <h2 className="fw-normal mb-2 pb-3" style={{ letterSpacing: '1px' }}>{ t('Billing Address') }</h2>
                                    </div>
                                    
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="street">{ t('Street') } *</label>
                                        <input type="text" id="street" onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('street')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('street')) ? <div className="invalid-feedback">{registrationErrors.street}</div> : ''}
                                    </div>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="address_2">{ t('Street 2') }</label>
                                        <input type="text" id="address_2" onChange={handleInputChange} className={`form-control ${(registrationErrors.hasOwnProperty('address_2')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('address_2')) ? <div className="invalid-feedback">{registrationErrors.address_2}</div> : ''}
                                    </div>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="zip_code">{ t('Postal code') } *</label>
                                        <input type="text" id="zip_code" onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('zip_code')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('zip_code')) ? <div className="invalid-feedback">{registrationErrors.zip_code}</div> : ''}
                                    </div>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="city">{ t('City') } *</label>
                                        <input type="text" id="city" onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('city')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('city')) ? <div className="invalid-feedback">{registrationErrors.city}</div> : ''}
                                    </div>
                                    
                                    <div className="form-outline mb-2 col-lg-3 col-md-3 col-sm-12 col-sx-12">
                                        <h2 className="fw-normal mb-2 pb-3" style={{letterSpacing: '1px'}}>{ t('Delivery Address') }</h2>
                                    </div>
                                    <div className="form-outline mb-2 col-lg-4 col-md-4 col-sm-12 col-sx-12 py-3">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={onChangeCheck} />
                                            <label className="form-check-label" htmlFor="exampleCheck1">{t('Same as Billing Address')}</label>
                                        </div>
                                    </div>
                                    
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="delivery_street">{ t('Street') } *</label>
                                        <input type="text" id="delivery_street" value={registerForm.delivery_street || ""} onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('delivery_street')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('delivery_street')) ? <div className="invalid-feedback">{registrationErrors.delivery_street}</div> : ''}
                                    </div>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="delivery_address_2">{ t('Street 2') }</label>
                                        <input type="text" id="delivery_address_2" value={registerForm.delivery_address_2 || ""} onChange={handleInputChange} className={`form-control ${(registrationErrors.hasOwnProperty('delivery_address_2')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('delivery_address_2')) ? <div className="invalid-feedback">{registrationErrors.delivery_address_2}</div> : ''}
                                    </div>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="delivery_zip_code">{ t('Postal code') } *</label>
                                        <input type="text" id="delivery_zip_code" value={registerForm.delivery_zip_code || ""} onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('delivery_zip_code')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('delivery_zip_code')) ? <div className="invalid-feedback">{registrationErrors.delivery_zip_code}</div> : ''}
                                    </div>
                                    <div className="form-outline mb-2 col-lg-6 col-md-6 col-sm-12 col-sx-12">
                                        <label className="form-label" htmlFor="delivery_city">{ t('City') } *</label>
                                        <input type="text" id="delivery_city" value={registerForm.delivery_city || ""} onChange={handleInputChange} required="required" className={`form-control ${(registrationErrors.hasOwnProperty('delivery_city')) ? 'is-invalid' : ''}`} />
                                        {(registrationErrors.hasOwnProperty('delivery_city')) ? <div className="invalid-feedback">{registrationErrors.delivery_city}</div> : ''}
                                    </div>
                                </div>

                                <div className="pt-1 mb-4">
                                <center>
                                { (() => {
                                    if(location.pathname === "/signup") {
                                        return (
                                            <button disabled={loading} className="btn btn-dark btn-block col-lg-4" type="submit">{(loading) ? <LoadingSpinner /> : t('Next') }</button>
                                        )
                                    } else {
                                        return( 
                                            <button disabled={loading} className="btn btn-dark btn-block col-lg-4" type="submit">{ (loading) ? <LoadingSpinner /> : t('Continue as a guest') }</button> 
                                        )
                                    }
                                }) ()}
                                </center>
                                </div>
                                <p className="small text-muted" style={{color: '#393f81'}}>{ t('Back to') } <Link to="/login" style={{ color: '#393f81' }}>{ t('Login') }</Link></p>
                            </form>

                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
    </FrontendLayout>
  )
}
