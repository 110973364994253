import { t } from 'i18next'
import React from 'react'

export default function SidebarHotline() {

    const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';

    let mobile_no = (owner_data) ? owner_data.mobile_no: "";
    let contact_timing = (owner_data && owner_data.owner_settings.contact_timing) ? owner_data.owner_settings.contact_timing : "08:00 - 18:00 Uhr";

    const contactImage = (owner_data && owner_data.owner_settings && owner_data.owner_settings.contact_person_image_url) ? owner_data.owner_settings.contact_person_image_url : '/assets/images/images/hotline-service.png';

  return (
    <div className="col-md-3 order-md-1 mb-4 mt-5">
        <div className="card">
            <img src={contactImage} alt='hotline-services' className="card-img-top" />
            <div className="card-body text-center">
                <h5 className="card-title">{ t('Service Hotline') }</h5>
                <p className="card-text">{ contact_timing }</p>
                <a href={'tel:'+mobile_no}>{mobile_no}</a>
            </div>
        </div>
    </div>
  )
}
