import React, { useEffect } from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import { config } from '../constans';

export default function PrivacyPolicy() {

  let owner_id = localStorage.getItem('MAIN_OWNER_ID');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <FrontendLayout>
        <section className="container">
            <div className="row mt-3" style={{height:'700px'}}>
                    <iframe 
                      id="pages-IFrame" 
                      name="Privacy-Policy"
                      src={config.APP_URL+'page/'+owner_id+'/privacy-policy'} 
                      frameBorder="0"
                    ></iframe>
            </div>
        </section>
    </FrontendLayout>
  )
}
