import React from 'react'
import {useStripe, useElements, CardElement,} from '@stripe/react-stripe-js';
import { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux'
import {makeStripePayment } from './../Store/Slices/PaymentSlice'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next';
import StatusMessages, { useMessages } from './StatusMessages';
import { Link } from 'react-router-dom';
import { makeStripePaymentForMaterial } from '../Store/Slices/MaterialProductSlice';

export default function StripeCheckoutForm() {
    // Declerations
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    let isrecorder = localStorage.getItem('is_reorder');
    let order_id = localStorage.getItem('order_id');

    const selectedAddress = JSON.parse(localStorage.getItem('selected_address'));
    const appoinmentForm = JSON.parse(localStorage.getItem('appoinmentForm'));
    const container_id = JSON.parse(localStorage.getItem('container_id'));
    const selectedZIPCODE = JSON.parse(localStorage.getItem('selectedZIPCODE'));
    const materialCategory = JSON.parse(localStorage.getItem('selected_material_category'));
    const materialProduct = JSON.parse(localStorage.getItem('selected_material_product'));
    const materialContainer = JSON.parse(localStorage.getItem('material_container'));
    const material_qty = localStorage.getItem('material_qty');

    const is_material_order = localStorage.getItem('is_material_order');

    const coupondata = (localStorage.getItem('coupon_data') !== '' || localStorage.getItem('coupon_data')!==undefined) ? JSON.parse(localStorage.getItem('coupon_data')) : '0';

    // States
    const [loadingStripeBtn, setLoadingStripeBtn] = useState(false);
    const [payment_success, setPaymentSuccess] = useState(false);
    const [messages, addMessage] = useState('');
    const [payment_method, addPaymentMethod] = useState("");
    const [loadingStripeIbanBtn, setLoadingStripeIbanBtn] = useState(false);
    const [STRIPE_IBAN, SET_STRIPE_IBAN] = useState('');
    const [user_name, setUserName] = useState("");

    // Stripe IBan setup
    const handleIbanChange = (e) => {
        SET_STRIPE_IBAN(e.target.value);
    }
    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    }
    // Functions
    const handleSubmit = async (event) => {
        setLoadingStripeBtn(true);
        event.preventDefault();
        
        if (!stripe || !elements) {
            setLoadingStripeBtn(false);
            addMessage('Stripe.js has not yet loaded.');
            return;
        }

        const cardElement = elements.getElement(CardElement);
        
        const {error: stripeError} = stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: user_name,
            },
        })
        .then(function (result) {
            
            if(result.error) {
                addMessage(stripeError.message);
                setLoadingStripeBtn(false);
                return;
            } else {
                addPaymentMethod(result.paymentMethod.id);
                addMessage("Please Wait we are processing your order don't press back or refresh Page.....");
                
                let paymentApiParams;

                if(is_material_order){ // material order
                    paymentApiParams = {
                        owner_id: localStorage.getItem('MAIN_OWNER_ID'),
                        address_id: selectedAddress.id,
                        category_id: materialCategory.id,
                        product_id: materialProduct.id,
                        product_type_id: materialContainer.id,
                        qty: material_qty,
                        delivery_date: Math.round(+new Date(appoinmentForm.delivery_on) / 1000),
                        delivery_time: appoinmentForm.delivery_time,
                        payment_method: result.paymentMethod.id,
                        pincode: selectedZIPCODE[0].name,
                        payment_type: 'stripe',
                        order_type: 'new_order',
                        coupon_id: (coupondata) ? coupondata.id : '0',
                        placement_option: appoinmentForm.placement_option,
    
                        
                    };

                    if(materialContainer.container_type == 'container'){
                        paymentApiParams = {...paymentApiParams, pickup_date: Math.round(+new Date(appoinmentForm.pickup_on)/1000),
                        pickup_time: appoinmentForm.delivery_time};
                    }

                    dispatch(makeStripePaymentForMaterial(paymentApiParams)).then((res) => {
                        if (res.status === 200) {
                            toast.success(res.message);
                            localStorage.removeItem('appoinmentForm');
                            localStorage.removeItem('material_container_price');
                            localStorage.removeItem('material_container');
                            localStorage.removeItem('selected_material_product');
                            localStorage.removeItem('material_product_id');
                            localStorage.removeItem('vat_tax');
                            localStorage.removeItem('material_category_id');
                            localStorage.removeItem('skiphire_guest_user');
                            localStorage.removeItem('coupon_data');
                            localStorage.removeItem('material_appoinmentForm');
                            localStorage.removeItem('is_material_order');
                            localStorage.removeItem('material_container_name');
                            localStorage.removeItem('material_qty');
                            localStorage.removeItem('selected_material_category');
                            localStorage.removeItem('material_loginFromPaymentSteps');
                            localStorage.removeItem('selected_address');
                            localStorage.removeItem('final_order_price');
                            localStorage.removeItem('material_container_id');
                            localStorage.removeItem('selectedZIPCODE');
                            localStorage.removeItem('is_reorder');
                            localStorage.removeItem('order_id');
                            localStorage.removeItem('new_reorder_container_id');
                            // navigate('/');
                            elements.getElement(CardElement).clear();
                            setUserName('');
                            setPaymentSuccess(true);
                    
                        } else {
                            setPaymentSuccess(false);
                            // addPaymentMethod("");
                            toast.error(res.message);
                        }
                        setLoadingStripeBtn(false);
                    });

                }else{ // container order
                    paymentApiParams = {
                        owner_id: localStorage.getItem('MAIN_OWNER_ID'),
                        address_id: selectedAddress.id,
                        container_id: container_id,
                        garbage_id: localStorage.getItem('garbage_id'),
                        pickup_date: Math.round(+new Date(appoinmentForm.pickup_on)/1000),
                        delivery_date: Math.round(+new Date(appoinmentForm.delivery_on) / 1000),
                        delivery_time: appoinmentForm.delivery_time,
                        pickup_date: Math.round(+new Date(appoinmentForm.pickup_on)/1000),
                        pickup_time: appoinmentForm.pickup_time,
                        payment_method: result.paymentMethod.id,
                        pincode: selectedZIPCODE[0].name,
                        payment_type: 'stripe',
                        order_type: 'new_order',
                        coupon_id: (coupondata) ? coupondata.id : '0',
                        placement_option: appoinmentForm.placement_option,
    
                        
                    };

                    dispatch(makeStripePayment(paymentApiParams)).then((res) => {
                        if (res.status === 200) {
                            toast.success(res.message);
                            localStorage.removeItem('appoinmentForm');
                            localStorage.removeItem('material_container_price');
                            localStorage.removeItem('material_container');
                            localStorage.removeItem('selected_material_product');
                            localStorage.removeItem('material_product_id');
                            localStorage.removeItem('vat_tax');
                            localStorage.removeItem('material_category_id');
                            localStorage.removeItem('skiphire_guest_user');
                            localStorage.removeItem('coupon_data');
                            localStorage.removeItem('material_appoinmentForm');
                            localStorage.removeItem('is_material_order');
                            localStorage.removeItem('material_container_name');
                            localStorage.removeItem('material_qty');
                            localStorage.removeItem('selected_material_category');
                            localStorage.removeItem('material_loginFromPaymentSteps');
                            localStorage.removeItem('selected_address');
                            localStorage.removeItem('final_order_price');
                            localStorage.removeItem('material_container_id');
                            localStorage.removeItem('selectedZIPCODE');
                            localStorage.removeItem('is_reorder');
                            localStorage.removeItem('order_id');
                            localStorage.removeItem('new_reorder_container_id');
                            // navigate('/');
                            elements.getElement(CardElement).clear();
                            setUserName('');
                            setPaymentSuccess(true);
                    
                        } else {
                            setPaymentSuccess(false);
                            // addPaymentMethod("");
                            toast.error(res.message);
                        }
                        setLoadingStripeBtn(false);
                    });
                }
            }
            
        }).catch((err) => {
            toast.error(err);
            setLoadingStripeBtn(false);
        });
    };

    const handleSubmitIBanClick = async (e) => {
        setLoadingStripeIbanBtn(true)
        if (STRIPE_IBAN === '') {
            toast.error('Please enter IBAN number!');
            setLoadingStripeIbanBtn(false)
            return;
        }
        let paymentApiParams = {
            owner_id: localStorage.getItem('MAIN_OWNER_ID'),
            address_id: selectedAddress.id,
            container_id: container_id,
            garbage_id: id,
            pickup_date: Math.round(+new Date(appoinmentForm.pickup_on)/1000),
            delivery_date: Math.round(+new Date(appoinmentForm.delivery_on) / 1000),
            pickup_time: appoinmentForm.delivery_time,
            delivery_time: appoinmentForm.delivery_time,
            pincode: selectedZIPCODE[0].name,
            payment_type: 'bank_transfer',
            order_type: 'new_order',
            iban:STRIPE_IBAN,
            coupon_id: (coupondata) ? coupondata.id : '0',
            placement_option: appoinmentForm.placement_option,
        };

        if (isrecorder === '1' && order_id >= 0) {
            paymentApiParams = {...paymentApiParams, order_type:'swap_order', order_id:order_id}
        }
        
        dispatch(makeStripePayment(paymentApiParams)).then((res) => {
            if (res.status === 200) {
                toast.success(res.message);
                localStorage.removeItem('appoinmentForm');
                // localStorage.removeItem('container_id');
                // localStorage.removeItem('container_price');
                localStorage.removeItem('selectedZIPCODE');
                localStorage.removeItem('selected_address');
                localStorage.removeItem('is_reorder');
                localStorage.removeItem('order_id');
                localStorage.removeItem('garbage_id');
                localStorage.removeItem('new_reorder_container_id');
                // localStorage.removeItem('skiphire_guest_user');
                navigate('/');
            } else {
                toast.error(res.message);
            }
            setLoadingStripeIbanBtn(false);
        });
    }

    return (
        <>
            { (() => {
                if(loadingStripeBtn === true) {
                    return (<span role='alert' className='alert alert-dark my-2' >{messages}</span>)
                } else if(payment_method !== "" && loadingStripeBtn === false && payment_success) {
                    
                    return (
                        <div className="bg-light rounded">
                            <h1>{t('Thank You')}</h1>
                            <p className="lead"> {t('Thank you for your Order')} </p>
                            <p><b>{t('Payment Method')}:</b> {t('Credit Card')}</p>
                            <Link className="btn btn-lg btn-primary" to="/orders" role="button">{t('View Orders')} »</Link>
                        </div>
                    )
                }
                
            }) ()}
            
            { payment_success === false &&
                <form onSubmit={handleSubmit}>
                    <div className="col-md-8 mt-3">
                        <label className="labels">{t('Name')}</label>
                        <input 
                            type="text" 
                            className="form-control h-50" 
                            onChange={handleUserNameChange}
                            value={user_name} 
                            id="user_name" 
                            required
                        />
                    </div>
                    <div className="col-md-8 mt-3">
                    <CardElement className='form-control'/>
                    </div>
                    <button className="btn theme-btn mt-2 rounded-pill" disabled={!stripe || loadingStripeBtn}>{loadingStripeBtn && <LoadingSpinner />} { t('Submit') }</button>
                </form>
            }
            
            
            {/* <div className='mt-2' style={{"width": "100%","height": '20px', 'borderBottom': '1px solid black', 'textAlign': 'center'}}>
                <span className='bg-light mt-4' style={{ 'fontSize': '20px', 'padding': '0 10px' }}>{ t('OR') }</span>
            </div>
            <div className="col-md-12 mt-3">
                <label className="labels">{ t('IBan') }</label>
                <input type="text" className="form-control h-50" onChange={handleIbanChange} value={STRIPE_IBAN} id="iban" />
                <button type='button' onClick={handleSubmitIBanClick} className="btn theme-btn mt-2 rounded-pill" disabled={!stripe}>{loadingStripeIbanBtn && <LoadingSpinner /> } { t('Submit With IBan') }</button>
            </div> */}
        </>
    )
}
