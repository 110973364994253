import React from 'react'
import FrontendLayout from '../../Layout/FrontendLayout'
import YellowHeader from '../../Components/YellowHeader'
import SidebarHotline from '../../Components/SidebarHotline'
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeMaterialProductList, getMaterialProduct } from '../../Store/Slices/MaterialProductSlice';
import { useEffect } from 'react';
import MaterialProductCard from '../../Components/MaterialProductCard';

export default function MaterialProduct() {

    const dispatch = useDispatch();

    const materialCategory = JSON.parse(localStorage.getItem('selected_material_category'));
    const selectedZIPCODE = JSON.parse(localStorage.getItem('selectedZIPCODE'));
    const productList = useSelector(storeMaterialProductList);


    useEffect(() => {
        const params = { 
            owner_id: localStorage.getItem('MAIN_OWNER_ID'), 
            category_id: materialCategory.id,
            postcode :  selectedZIPCODE[0].name
        };

        dispatch(getMaterialProduct(params));
    }, [dispatch])

    return (
    <FrontendLayout>
        <section className="container">
            <div className="row mt-5">
                <YellowHeader title={ t('Material Product') } />
                <SidebarHotline />
                <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 col-md-12 mt-5 order-md-2">
                    <h2>{materialCategory.title}</h2>
                    <div className='row'>
                        { productList.length > 0 && productList.map(product => (
                            <div className="col-md-6" key={product.id}>
                                <MaterialProductCard product={product} />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Modal */}
                <div className="modal fade" id="exampleModalProduct" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"></h5>
                                <button type="button" className="btn-close closeThisPopup" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </FrontendLayout>
    )
}
