import React from 'react'
import SidebarHotline from '../Components/SidebarHotline'
import FrontendLayout from '../Layout/FrontendLayout'
import LoadingSpinner from '../Components/LoadingSpinner'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { sendContactUs, storeContactUsErrors } from '../Store/Slices/ContactUsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import YellowHeader from '../Components/YellowHeader'

export default function ContactUs() {

    const dispatch = useDispatch();
    let contactUsErrors = useSelector(storeContactUsErrors);
    const [ContactUsBtnLoading, setContactUsBtnLoading] = useState(false);
    const [ContactUsFrom, setContactUsFrom] = useState({
        subject: '',
        body: '',
        name: '',
        email: '',
        phone: '',
        owner_id: localStorage.getItem('MAIN_OWNER_ID')
    })

    const handleInputChange = (e) => {
        setContactUsFrom({ ...ContactUsFrom, [e.target.id]: e.target.value });
    }

    const handleContactUsBtnClick = async () => {
        setContactUsBtnLoading(true);
        dispatch(sendContactUs(ContactUsFrom)).then((data) => {
            if (data.status === 200) {
                toast.success(data.message);
                setContactUsFrom({
                    subject: '',
                    body: '',
                    name: '',
                    email: '',
                    phone: '',
                    owner_id: localStorage.getItem('MAIN_OWNER_ID')
                });
            } else {
                toast.error(data.message);
            }
            setContactUsBtnLoading(false);
        })
    }

    return (
        <FrontendLayout>
            <section className="container">
                <div className="row mt-5">
                    <YellowHeader title={ t('Contact us') } />
                    <SidebarHotline />
                    <div className="col-md-9 order-md-2 mt-5">
                        <div className="mb-3">
                            <label className="labels">{ t('Name') } *</label>
                                <input type="text" className={`form-control h-50 ${contactUsErrors && contactUsErrors.hasOwnProperty('name') && 'is-invalid'}`} id='name' value={ContactUsFrom.name} onChange={handleInputChange}/>
                                {contactUsErrors && contactUsErrors.hasOwnProperty('name') && <div className="invalid-feedback">{contactUsErrors.name[0]}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="labels">{ t('Email') } *</label>
                                <input type="text" className={`form-control h-50 ${contactUsErrors && contactUsErrors.hasOwnProperty('email') && 'is-invalid'}`} id='email' value={ContactUsFrom.email} onChange={handleInputChange}/>
                                {contactUsErrors && contactUsErrors.hasOwnProperty('email') && <div className="invalid-feedback">{contactUsErrors.email[0]}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="labels">{ t('Telephone') } *</label>
                                <input type="text" className={`form-control h-50 ${contactUsErrors && contactUsErrors.hasOwnProperty('phone') && 'is-invalid'}`} id='phone' value={ContactUsFrom.phone} onChange={handleInputChange}/>
                                {contactUsErrors && contactUsErrors.hasOwnProperty('phone') && <div className="invalid-feedback">{contactUsErrors.phone[0]}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="labels">{ t('Subject') } *</label>
                                <input type="text" className={`form-control h-50 ${contactUsErrors && contactUsErrors.hasOwnProperty('subject') && 'is-invalid'}`} id='subject' value={ContactUsFrom.subject} onChange={handleInputChange}/>
                                {contactUsErrors && contactUsErrors.hasOwnProperty('subject') && <div className="invalid-feedback">{contactUsErrors.subject[0]}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="labels">{ t('Message') } *</label>
                            <textarea rows="10" className={`form-control ${contactUsErrors && contactUsErrors.hasOwnProperty('body') && 'is-invalid'}`} id='body' value={ContactUsFrom.body} onChange={handleInputChange}/>
                            {contactUsErrors && contactUsErrors.hasOwnProperty('body') && <div className="invalid-feedback">{contactUsErrors.body}</div>}
                        </div>
                        <div className="mb-3">
                            <button disabled={ContactUsBtnLoading} onClick={handleContactUsBtnClick} className="col-md-2 btn theme-btn mt-2 rounded-pill">{ContactUsBtnLoading && <LoadingSpinner />} { t('Send') }</button>
                        </div>
                    </div>
                </div>
            </section>
        </FrontendLayout>
  )
}
