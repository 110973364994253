import React from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import { config } from '../constans'
import { useEffect } from 'react';

export default function Imprint() {

  let owner_id = localStorage.getItem('MAIN_OWNER_ID');
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  

  return (
    <FrontendLayout>
        <section className="container">
            <div className="row mt-3" style={{height:'700px'}}>
                    <iframe 
                      id="impprintIframe"
                      name='imprint'
                      src={config.APP_URL+'page/'+owner_id+'/imprint'}
                      frameBorder="0"
                    ></iframe>
            </div>
        </section>
    </FrontendLayout>
  )
}
