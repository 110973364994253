import React from 'react'
import { Children } from 'react'
import './../Assets/css/styles.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Footer from './Footer'
import FooterSupportImage from './FooterSupportImage'
import Header from './Header'
import "bootstrap/dist/js/bootstrap.bundle.min";
import CookieConsent from "react-cookie-consent";

export default function FrontendLayout({children}) {
  return (
    <>
        <Header />
          {children}
          <FooterSupportImage />
          <ToastContainer icon={true} theme="colored"/>
        <Footer />
        <CookieConsent
          enableDeclineButton 
          declineButtonText="Ablehnen"
          location="bottom"
          buttonText="Zustimmen"
          cookieName="skip-hire-cookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b" }}
          expires={150}
        >
          In den Einstellungen kannst du erfahren, welche Cookies wir verwenden oder sie ausschalten.
          {/* <span style={{ fontSize: "15px" }}></span> */}
        </CookieConsent>
    </>
  )
}
