import React from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../Store/Slices/UserAuthSlice';
import LoadingSpinner from '../Components/LoadingSpinner';
import { toast } from 'react-toastify';

export default function ForgotPassword() {
    const dispatch = useDispatch()
    const [forgotPasswordBtnLoading, setforgotPasswordBtnLoading] = useState(false)
    const [forgotPasswordErrors, setforgotPasswordErrors] = useState({})
    const [forgotPasswordForm, setforgotPasswordForm] = useState({
        owner_id: localStorage.getItem('MAIN_OWNER_ID', 3),
        email:''
    })

    const handleInputChange = (e) => {
        setforgotPasswordForm({ ...forgotPasswordForm, email: e.target.value });
    }

    const handleForgotPasswordBtnClick = async () => {
        setforgotPasswordBtnLoading(true);
        dispatch(forgotPassword(forgotPasswordForm)).then((response) => {
            if (response.status === 0) {
                if (response.hasOwnProperty('errors')) {
                    setforgotPasswordErrors(response.errors);
                } else {
                    toast.error(response.message)
                    setforgotPasswordErrors({});
                }
            } else {
                setforgotPasswordErrors({});
                setforgotPasswordForm({ email: '' });
                toast.success(response.message)
            }
            setforgotPasswordBtnLoading(false);
        });
    }


  return (
    <FrontendLayout>
            <div className='mx-auto'>
            <section className="mt-5" >
                <div className="container py-2 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col col-xl-6">
                        <div className="card bg-light" style={{borderRadius: '1rem'}}>
                        <div className="row g-0">
                            <div className="col-md-12 col-lg-12 d-flex align-items-center">
                            <div className="card-body p-4 p-lg-5 text-black">
                                <form>
                                    <h5 className="fw-normal mb-2 pb-3" style={{letterSpacing: '1px'}}>{ t("Reset your password") }</h5>
                                    <div className="form-outline mb-2">
                                        <label className="form-label" htmlFor="email">{ t('EMail') }</label>
                                        <input value={forgotPasswordForm.email} onChange={handleInputChange} type="email" id="email" className={`form-control ${(forgotPasswordErrors.hasOwnProperty('email')) ? 'is-invalid' : ''}`} />
                                        {(forgotPasswordErrors.hasOwnProperty('email')) ? <div className="invalid-feedback">{forgotPasswordErrors.email}</div> : ''}
                                    </div>

                                    <div className="pt-1 mb-4">
                                        <button disabled={forgotPasswordBtnLoading} onClick={handleForgotPasswordBtnClick} className="btn btn-dark btn-block" type="button"> {forgotPasswordBtnLoading && <LoadingSpinner />} { t('Reset Password') }</button>
                                    </div>
                                    <p className="small text-muted" style={{color: '#393f81'}}>{ t('Back to') } <Link to="/login"
                                                      style={{ color: '#393f81' }}>{ t('Login') }</Link></p>
                                </form>

                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </FrontendLayout>
  )
}
