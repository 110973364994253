import React from 'react'
import FrontendLayout from '../../Layout/FrontendLayout'
import { t } from 'i18next';
import SidebarHotline from '../../Components/SidebarHotline';
import YellowHeader from '../../Components/YellowHeader';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { checkPostcode } from '../../Store/Slices/GarbageSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetLocalStorageValues } from '../../Store/Slices/MaterialProductSlice';

export default function MaterialZipcode() {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [zipcode, setZipcode] = useState();


    const handleZipChangeEvent = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setZipcode(value)
    }

    const checkZipCode = () => {
        setLoading(true)
        dispatch(checkPostcode({ owner_id: localStorage.getItem('MAIN_OWNER_ID'), postcode: zipcode })).then((data) => {
            setLoading(false)
            if (data.status === 0) {
                toast.error(data.message)
            } else {
                dispatch(resetLocalStorageValues())
                localStorage.setItem('selectedZIPCODE', JSON.stringify(data.data));
                toast.success('Zipcode is valid')
                navigate('/material-category-list')
            }
        });
    }

    return (
    <FrontendLayout>
        <section className="container">
            <div className="row mt-5">
                <YellowHeader title={ t('Materials') } />
                <SidebarHotline />
                <div className="col-md-9 order-md-2 mt-5 text-center">
                    <h4>{ t('Please give me your zip code') }</h4>
                    <div className='form-group col-6 mx-auto'>
                        <input type="text" className='form-control' value={zipcode} onChange={handleZipChangeEvent} placeholder={ t('Enter zip code') } pattern="/^-?\d+\.?\d*$/" maxLength="7"/>
                    </div>
                    <div className="mt-3 text-left"><button onClick={checkZipCode} disabled={loading} className="btn theme-btn profile-button btn-lg" type="button">{loading && <LoadingSpinner />}{ t('Search Material') }</button></div>
                </div>
            </div>
        </section>
    </FrontendLayout>
    )
}
